import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { observer } from 'mobx-react-lite';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, RouteProps, Router, Switch, useHistory } from 'react-router-dom';

import { appInsights } from './AppInsights';
import { CustomLoader } from './components/basic/CustomeLoader/CustomLoader';
import { ConfirmProvider } from './components/Confirm';
import Nav from './components/copilot/Nav';
import CardWarningComponent from './components/general/CardWarning.component';
import Layout from './components/general/Layout';
import TourComponent from './components/onboarding/TourComponent';
import { ServiceProvider, Services } from './components/ServiceProvider';
import SessionHomePage from './components/sessions/SessionHomePage.component';
import history from './history';
import { LatencyNetworkMock } from './mocks/NetworkMock';
import { PageConfirmEmail as ConfirmEmailPage } from './pages/ConfirmEmail.page';
import CreateOrganisationPage from './pages/CopilotOrganisation/CreateOrganisation.page';
import Organisation from './pages/CopilotOrganisation/Organisation.page';
import NotFound from './pages/NotFound.page';
import Plans from './pages/Plans.page';
import { RegistrationCompletePage } from './pages/RegistrationComplete.page';
import { SignInPage } from './pages/SignIn/SignIn.page';
import SignUpPage from './pages/SignUp/SignUp.page';
import SpacesTab from './pages/Spaces/index';
import SpacesPage from './pages/Spaces/Spaces.page';
import { AuthService } from './services/auth/auth.service';
import { AuthServiceMock } from './services/auth/auth.service.mock';
import { CopilotService } from './services/copilot/copilot.service';
import { DocumentsService } from './services/documents/documents.service';
import { EvaService } from './services/eva/eva.service';
import { initUserSession } from './services/initUserSession';
import { MetricService } from './services/metric/metric.service';
import { OrganisationService } from './services/organisation/organisation.service';
import { PaymentsService } from './services/payments/payments.service';
import { SessionService } from './services/session/session.service';
import { SpacesService } from './services/spaces/spaces.service';
import { StripeWebhookService } from './services/stripeWebhook/stripe.webhook.sevice';
import { UsersService } from './services/users/users.service';
import { store, StoreProvider, useStore } from './store';
import { handleMessage } from './utils/chromeExtension';
import { ENTERPRISE, BLUEBERRY, BLUEBERRY_PRO } from './utils/constants';
import 'react-loading-skeleton/dist/skeleton.css';

const networkMock = new LatencyNetworkMock(600);

// We are supposed to have mock services but it's too expensive
// to maintain for a MVP
const servicesMock: Services = {
    auth: new AuthServiceMock(networkMock),
    users: new UsersService(),
    documents: new DocumentsService(),
    payments: new PaymentsService(),
    spaces: new SpacesService(),
    session: new SessionService(),
    copilot: new CopilotService(),
    organisation: new OrganisationService(),
    eva: new EvaService(),
    stripeWebhook: new StripeWebhookService(),
    metric: new MetricService(),
};

const documentService = new DocumentsService();
const paymentsServices = new PaymentsService();
const spacesService = new SpacesService();
const sessionService = new SessionService();
const copilotService = new CopilotService();
const organisationService = new OrganisationService();
const stripeWebhookService = new StripeWebhookService();
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });
const evaService = new EvaService();
const services: Services = {
    auth: new AuthService(networkMock),
    users: new UsersService(),
    documents: documentService,
    payments: paymentsServices,
    spaces: spacesService,
    session: sessionService,
    copilot: copilotService,
    organisation: organisationService,
    eva: evaService,
    stripeWebhook: stripeWebhookService,
    metric: new MetricService(),
};

const TITLE = 'Blankstate';

const TOKEN_RENEWAL_INTERVAL = 10 * 60 * 1000; // 10 minutes
async function renewToken() {
    await services.auth.renewToken();
    setTimeout(renewToken, TOKEN_RENEWAL_INTERVAL);
}

const App = observer(() => {
    useEffect(() => {
        (async () => {
            if (store.user.token) {
                await renewToken();
                initUserSession(services, store);
            }
        })();
    }, []);

    useEffect(() => {
        initDayjs();
    }, []);

    //extension message listener
    useEffect(() => {
        window.addEventListener('message', handleMessage);

        // appInsights.trackException({  error : true});
        appInsights.trackEvent({ name: 'Start Pages', properties: { customProperty: 'customValue' } });

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const [step, setStep] = useState(0);

    const setCurrentStep = (step: React.SetStateAction<number>) => {
        switch (step) {
            case 0:
                history.push('/home');
                break;
        }
        setStep(step);
    };

    return (
        <div className='h-screen bg-backgroundColor'>
            <QueryClientProvider client={queryClient}>
                <StoreProvider>
                    <ServiceProvider services={process.env.REACT_APP_USE_MOCK ? servicesMock : services}>
                        <ConfirmProvider>
                            <Router history={history}>
                                <div className='text-secondary bg-backgroundColor'>
                                    <Helmet>
                                        <title>{TITLE}</title>
                                    </Helmet>
                                    <CardWarningComponent />
                                </div>
                                <TourComponent
                                    currentStep={step}
                                    handleCurrentStep={function (value: React.SetStateAction<number>): void {
                                        setCurrentStep(value);
                                    }}
                                >
                                    <Switch>
                                        <Route exact path='/' component={SignInPage} />
                                        <Route path='/sign-in' component={SignInPage} />
                                        <Route path='/sign-up' component={SignUpPage} />
                                        <Route path='/confirm-email' component={ConfirmEmailPage} />
                                        <Route path='/registration-complete' component={RegistrationCompletePage} />
                                        <Route exact path='/error/:status' component={NotFound} />
                                        <Layout>
                                            <Switch>
                                                <ProtectedRoute
                                                    exact
                                                    path='/home'
                                                    component={SessionHomePage}
                                                    allowedTypes={[ENTERPRISE, BLUEBERRY, BLUEBERRY_PRO]}
                                                />
                                                <ProtectedRoute
                                                    path='/workspace'
                                                    component={SpacesTab}
                                                    allowedTypes={[ENTERPRISE, BLUEBERRY, BLUEBERRY_PRO]}
                                                />
                                                <ProtectedRoute
                                                    path='/spaces'
                                                    component={SpacesPage}
                                                    allowedTypes={[ENTERPRISE, BLUEBERRY, BLUEBERRY_PRO]}
                                                />
                                                <ProtectedRoute
                                                    path='/organisation'
                                                    component={Organisation}
                                                    allowedTypes={[ENTERPRISE, BLUEBERRY, BLUEBERRY_PRO]}
                                                />
                                                <ProtectedRoute
                                                    path='/account-details'
                                                    component={Nav}
                                                    allowedTypes={[ENTERPRISE, BLUEBERRY, BLUEBERRY_PRO]}
                                                />
                                                {/* <ProtectedRoute
                                                    path='/plans'
                                                    component={Plans}
                                                    allowedTypes={[BLUEBERRY, BLUEBERRY_PRO]}
                                                /> */}
                                                <ProtectedRoute
                                                    path='/create-organisation'
                                                    component={CreateOrganisationPage}
                                                    allowedTypes={[BLUEBERRY, BLUEBERRY_PRO]}
                                                />
                                                <Route component={NotFound} />
                                            </Switch>
                                        </Layout>
                                    </Switch>
                                </TourComponent>
                            </Router>
                        </ConfirmProvider>
                    </ServiceProvider>
                </StoreProvider>
            </QueryClientProvider>
        </div>
    );
});
interface ProtectedRouteProps extends RouteProps {
    allowedTypes: string[];
}

export const ProtectedRoute = observer((props: ProtectedRouteProps) => {
    const { initiated, user } = useStore();
    const history = useHistory();
    const userType = user.userData?.userType;

    const loadingScreen = (
        <div className='flex items-center justify-center w-100 h-100 '>
            <CustomLoader />
        </div>
    );

    useEffect(() => {
        const token = user.token;
        if (!token) history.push('/sign-in');
    }, [history, user]);

    if (!userType) return loadingScreen;
    if (props.allowedTypes.includes(userType)) {
        return (
            <Route
                {...props}
                component={initiated ? props.component : undefined}
                children={initiated ? props.children : loadingScreen}
            />
        );
    } else {
        return <NotFound />;
    }
});

interface EvaProtectedRouteProps extends RouteProps {
    subscription: boolean;
    isUserAdmin: boolean;
}

export const EvaProtectedRoute = observer((props: EvaProtectedRouteProps) => {
    const { initiated, user } = useStore();
    const userType = user.userData?.userType;

    const loadingScreen = (
        <div className='flex items-center justify-center w-100 h-100  '>
            <CustomLoader />
        </div>
    );

    if (!userType) return loadingScreen;
    if (props.subscription && props.isUserAdmin) {
        return (
            <Route
                {...props}
                component={initiated ? props.component : undefined}
                children={initiated ? props.children : loadingScreen}
            />
        );
    } else {
        setTimeout(() => {
            return <NotFound />;
        }, 1000);
        return loadingScreen;
    }
});

const initDayjs = () => {
    dayjs.extend(updateLocale);

    dayjs.updateLocale('en', {
        relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: 'a few seconds',
            m: 'a minute',
            mm: '%d min',
            h: 'an hour',
            hh: '%d hours',
            d: 'a day',
            dd: '%d days',
            M: 'a month',
            MM: '%d months',
            y: 'a year',
            yy: '%d years',
        },
    });
};

export default App;
