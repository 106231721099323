import { useMemo } from 'react';

import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../../Redux/store';
import { categoryList } from '../../../eva/components/metrics/metricsType';

interface MetricsGaugeChartProps {
    isLoading: boolean;

    isFetching: boolean;
}

//default Guage chart parameters
const MetricGaugeChart: React.FC<MetricsGaugeChartProps> = ({ isLoading, isFetching }) => {
    const chartData = useSelector((state: RootState) => state.TrendMetric.chartData);

    const colorStops = chartData.map((item) => {
        const colorObj = categoryList.find((obj) => obj.key === item.category);
        return colorObj?.color;
    });

    // Memoized chart values
    const chartValues = useMemo(() => {
        if (isFetching || isLoading) {
            return [0];
        }
        const data = chartData?.[0]?.data;
        return Array.isArray(data) && data.length > 0 ? data : [0];
    }, [chartData, isFetching, isLoading]);

    // Default chart options
    const options: ApexOptions = {
        chart: {
            type: 'radialBar',
            width: '100%',
            toolbar: { show: false },
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: { background: '#e7e7e7', margin: 5 },
                dataLabels: {
                    name: { show: false },
                    value: { offsetY: 0, fontSize: '22px' },
                },
            },
        },
        fill: { colors: colorStops },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        labels: ['Average Results'],
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
        },
    };

    return (
        <div style={{ height: '100%', width: '100%' }} className='flex items-center justify-center'>
            <Chart key={chartValues.toString()} options={options} series={chartValues} type='radialBar' height={300} />
        </div>
    );
};

export default MetricGaugeChart;
