/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';

import { Box, ClickAwayListener, Dialog, Drawer, makeStyles } from '@material-ui/core';
import { useTour } from '@reactour/tour';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { appInsights } from '../../../../../AppInsights';
import { ReactComponent as FileErrorIcon } from '../../../../../assets/icons/File_Error_Icon.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/icon-arrow-left-up.svg';
import fillMetamarkerIcon from '../../../../../assets/icons/icon-fill-metamarker.svg';
import { useSubscriptionDetail } from '../../../../../contexts/subscription.context';
import { useOrganisationForm } from '../../../../../hooks/useOrganisation';
import { useSettings } from '../../../../../hooks/useSettings';
import {
    getEvaId,
    setAddNewProtocol,
    setAddProtocolLoading,
    setApiNuances,
    setArchiveEvaId,
    setBluePrintEvaLoading,
    setChangeNuancesScreen,
    setCheCkNuancesLeg,
    setCopyEnableProtocolType,
    setCopyOfProtocolName,
    setCopyOfProtocolType,
    setCopyTriggerIcon,
    setCopyTriggerNumber,
    setCurrentProtocolNuances,
    setDeleteProtocol,
    setDraftProtocols,
    setEnableProtocolType,
    setEvaProtocolsData,
    setEvaTitle,
    setFetchError,
    setIsChangeProtocolData,
    SetIsCreateEvaFromBlueprints,
    setMetaMarkerValidation,
    setNuancesValidate,
    setNuancesWithThreshold,
    setOpenBluePrintModal,
    setOpenLeftSidePanel,
    setOrbitLoader,
    setProtocolEditMode,
    setProtocolErrorMessage,
    setProtocolIndex,
    setProtocolName,
    setProtocolType,
    setRemoveArchiveEvaIdConfirmation,
    setRestartEvaLoading,
    setSaveConfirmModal,
    setSaveProtocolEvent,
    setTemplateEvaLoading,
    setTriggerIcon,
    setTriggerNumber,
    setUnarchiveLoading,
    setUpdateData,
    setUpdateEvaLoading,
} from '../../../../../Redux/EvaInfo';
import { RootState } from '../../../../../Redux/store';
import { store } from '../../../../../store';
import { AnimatedCircle } from '../../../../basic/AnimatedCircle';
import { Button } from '../../../../basic/Button.component';
import { CustomLoader } from '../../../../basic/CustomeLoader/CustomLoader';
import ErrorMessage from '../../../../basic/ErrorMessage.component';
import { useServices } from '../../../../ServiceProvider';
import {
    EvaCreationMethod,
    EvaType,
    Gradients,
    MetaMarkerData,
    MetaMarkerItem,
    Nuances,
    SubScriptionType,
} from '../types';
import { getRgbColorCode } from './ColorCode';
import { CreateEvaOrbit } from './createEva/CreateEvaOrbit';
import { compareMetaMarkers, compareNuanceData, convertToThumbData } from './createEva/EvaCompare';
import EvaDialog from './EvaDialog.component';
import EvaHeader from './EvaHeader';
import EvaluateHint from './evaluate/EvaluateHint.component';
import { EvaTemplate } from './evaTemplate';
import EvaTitleDescription from './EvaTitleDescription.component';
import planet from './img/planet-blue.png';
import CtrlLeftSidePanel from './publishedPlanet/CtrlLeftSidePannel/CtrlLeftSidePanel';
import './style.css';
import RightSidePanel from './RightSidePanel.component';
import Sidebar from './Sidebar';
import TourDialog from './TourDialog.component';
import './slider/slider.css';

const useStyles = makeStyles(() => ({
    drawer: {
        width: '23.021vw',
        top: '130px !important',
        left: '30px',
        flexShrink: 0,
        '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
        },
    },
    drawerPaper: {
        boxSizing: 'border-box',
        width: '23.021vw',
        top: '138px !important',
        left: '32px',
        height: ' -webkit-fill-available',
        '@media (max-width: 991px)': {
            padding: '10px',
        },
        background: 'rgba(111, 191, 225, 0.1) !important',
        borderRadius: '15px',
        boxShadow: 'none',
    },
    evaField: {
        borderRadius: 7,
        backgroundColor: 'transparent',
        width: '100%',
        '& .MuiInput-underline:before, & .MuiInput-underline:after': {
            display: 'none',
        },
        '& .MuiInput-underline input': {
            textAlign: 'center',
        },
        '& .MuiInputBase-input::placeholder': {
            opacity: 1,
        },
        color: 'rgba(255, 255, 255, 0.75)',
    },
    placeHolder: {
        position: 'absolute',
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 12,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    headerTitle: {
        wordBreak: 'break-all',
    },
}));

export const evaTitleFieldStyle = {
    fontSize: 16,
    fontWeight: 600,
};

interface CreateEvaModelProps {
    open: boolean;
    handleClose: () => void;
    isPublished: boolean;
    setPublishedEvaExist: (value: boolean) => void;
    existingEvaId?: string | null;
    isPublishedEvaModel?: boolean;
    isArchived?: boolean;
    archive: boolean;
    setArchive: (value: boolean) => void;
    restarted?: boolean;
    protocolIndex?: number | null;
}

const CreateEvaModel: React.FC<CreateEvaModelProps> = ({
    open,
    handleClose,
    isPublished,
    setPublishedEvaExist,
    existingEvaId,
    isPublishedEvaModel,
    isArchived = false,
    archive,
    setArchive,
    restarted,
}) => {
    const defaultGradientsData = [
        { name: 'Significant', number: 1 },
        { name: 'High', number: 0.8 },
        { name: 'Moderate', number: 0.6 },
        { name: 'Low', number: 0.4 },
        { name: 'Minimal', number: 0.2 },
    ];
    const initialTrigger = 50;
    const classes = useStyles();
    const { eva, metric } = useServices();
    const modalRef = useRef<HTMLDivElement | null>(null);

    const {
        createEva,
        updateEva,
        getEvaByOrganisationId,
        deleteEva,
        archiveEva,
        unarchiveEva,
        getEvaByEvaId,
        checkUserPermission,
    } = useOrganisationForm();
    const { id: organisationId } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const [currentSlide, setCurrentSlide] = useState(0);

    const [isIconsShow, setIsIconsShow] = useState(false);
    const sliderRef = useRef<Slider | null>(null);
    const divRef = useRef(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [weight, setWeight] = useState<string>('1');

    const [isCreateMetaMarkerOpen, setIsCreateMetaMarkerOpen] = useState<null | HTMLElement>(null);
    const [addNuance, setAddNuance] = useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState({
        title: false,
        description: false,
        metaMarker: false,
        weight: false,
        nuance: false,
    });

    const [isTestMode, setIsTestMode] = useState(false);

    const [isNuanceRemoved, setIsNuanceRemoved] = useState<boolean>(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
    const [isMetaMarkerEnabled, setIsMetaMarkerEnabled] = useState(false);
    const [evaSlide, setEvaSlide] = useState(false);
    const [addMetaMarker, setAddMetaMarker] = useState(false);
    const [updateMetaMarker, setUpdateMetaMarker] = useState(false);
    const [gradients, setGradients] = useState<Gradients[]>(defaultGradientsData);
    const [generatedEva, setGeneratedEva] = useState('');
    const [isOpenPublishModal, setIsOpenPublishModal] = useState(false);
    const [metaMarkerDialog, setMetaMarkerDialog] = useState(false);
    const [testModeDialog, setTestModeDialog] = useState(false);
    const { setIsOpen } = useTour();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenRestartModal, setIsOpenRestartModal] = useState(false);
    const [isEvaPublished, setEvaPublished] = useState(false);
    const [creationMethod, setCreationMethod] = useState<string | null>(null);
    const [isRestarted, setIsRestarted] = useState(restarted);
    const [publishedAt, setPublishedAt] = useState<string | null>(null);

    // eva title and description toggle
    const [editTitle, setEditTitle] = useState(false);
    const [editDescription, setEditDescription] = useState(false);
    const onboardingTour = store.user.userData?.onboardingTour;
    const { updateOnboardingTour } = useSettings();

    // sidebar component state  use both component parent and child

    const [publishData, setPublishData] = useState<any>();

    const [labelShow, setLabelShow] = useState(true);
    const [holdSidebar, setHoldSidebar] = useState<boolean>(false);
    const [titleFocus, setTitleFocus] = useState(false);
    const [descriptionFocus, setDescriptionFocus] = useState(false);
    const titleInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLTextAreaElement>(null);

    const history = useHistory();

    const titleRef = useRef<HTMLDivElement | null>(null);
    const descriptionRef = useRef<HTMLDivElement | null>(null);
    const isCreateEvaFromBlueprints = useSelector((state: RootState) => state.EvaInfo.isCreateEvaFromBlueprints);
    const protocolIndex = useSelector((state: RootState) => state.EvaInfo.protocolIndex);
    const nuancesWithThreshold = useSelector((state: RootState) => state.EvaInfo.nuancesWithThreshold);
    const isProtocolEditMode = useSelector((state: RootState) => state.EvaInfo.isProtocolEditMode);
    const metaMarkerValue = useSelector((state: RootState) => state.EvaInfo.protocolName);
    const triggerNumber = useSelector((state: RootState) => state.EvaInfo.triggerNumber);
    const protocolType = useSelector((state: RootState) => state.EvaInfo.protocolType);
    const triggerIcon = useSelector((state: RootState) => state.EvaInfo.triggerIcon);
    const isEnableProtocolType = useSelector((state: RootState) => state.EvaInfo.isEnableProtocolType);
    const protocolThresholdValues = useSelector((state: RootState) => state.EvaInfo.protocolThresholdValues);
    const fileError = useSelector((state: RootState) => state.EvaInfo.fileError);
    const metaMarkerValidation = useSelector((state: RootState) => state.EvaInfo.metaMarkerValidation);
    const protocolErrorMessage = useSelector((state: RootState) => state.EvaInfo.protocolErrorMessage);
    const currentProtocolNuances = useSelector((state: RootState) => state.EvaInfo.currentProtocolNuances);
    const evaProtocolsData = useSelector((state: RootState) => state.EvaInfo.evaProtocolsData);
    const publishProtocols = useSelector((state: RootState) => state.EvaInfo.publishProtocols);
    const saveConfirmModal = useSelector((state: RootState) => state.EvaInfo.saveConfirmModal);
    const checkConfirmationScreen = useSelector((state: RootState) => state.EvaInfo.checkConfirmationScreen);
    const isDeleteProtocol = useSelector((state: RootState) => state.EvaInfo.isDeleteProtocol);
    const SaveProtocolEvent = useSelector((state: RootState) => state.EvaInfo.SaveProtocolEvent);
    const addNewProtocol = useSelector((state: RootState) => state.EvaInfo.addNewProtocol);
    const draftProtocols = useSelector((state: RootState) => state.EvaInfo.draftProtocols);
    const isMetricsMode = useSelector((state: RootState) => state.EvaInfo.isMetricsMode);
    const templateEvaLoading = useSelector((state: RootState) => state.EvaInfo.templateEvaLoading);
    const restartEvaLoading = useSelector((state: RootState) => state.EvaInfo.restartEvaLoading);
    const bluePrintLoading = useSelector((state: RootState) => state.EvaInfo.bluePrintEvaLoading);
    const evaId = useSelector((state: RootState) => state.EvaInfo.evaId);
    const openModal = useSelector((state: RootState) => state.EvaInfo.removeArchiveEvaIdConfirmation);
    const evaTitle = useSelector((state: RootState) => state.EvaInfo.evaTitle);
    const unarchiveLoading = useSelector((state: RootState) => state.EvaInfo.unarchiveLoading);
    const updateEvaLoading = useSelector((state: RootState) => state.EvaInfo.updateEvaLoading);
    const isEvaluateMode = useSelector((state: RootState) => state.EvaInfo.isEvaluateMode);

    const { organisation } = useServices();

    // evaluateMode
    const [evaluateMode, setEvaluateMode] = useState<boolean>(false);
    const [evaluateHint, setEvaluateHint] = useState<string | null>(null);
    const [actionType, setActionType] = useState<string | null>(null);
    const [newNuances, setNewNuances] = useState<{ nuance: string; threshold: number; oldNuances?: string }>({
        nuance: '',
        threshold: 0,
        oldNuances: '',
    });
    const [editUserResponse, setEditUserResponse] = useState<boolean>(false);

    //metricsMode
    const [updateMetricProtocol, setUpdateMetricProtocol] = useState<boolean>(false);

    const handleGenMetricProtocol = (
        mode: string,
        metaMarkerTitle: string,
        nuances: { nuance: string; threshold: number }[],
    ) => {
        if (mode === 'create') {
            dispatch(setTriggerNumber(50));

            dispatch(setEnableProtocolType(true));

            dispatch(setProtocolName(metaMarkerTitle));
            dispatch(setCopyOfProtocolName(metaMarkerTitle));

            dispatch(setTriggerIcon('>'));

            dispatch(setNuancesWithThreshold(nuances));
        } else {
            dispatch(setChangeNuancesScreen(true));
            dispatch(setApiNuances(nuances));
        }
    };

    const errors = [
        fileError && fileError,
        metaMarkerValidation && t('please_enter_protocol'),
        protocolErrorMessage && protocolErrorMessage,
    ].filter(Boolean);

    const subscription = useSubscriptionDetail();

    const toggleEditTitle = async () => {
        setEditTitle(!editTitle);
        setEditDescription(false);
    };

    const toggleEditDescription = async () => {
        setEditDescription(!editDescription);
        setEditTitle(false);
    };

    const RemoveArchiveIdConfirm = () => {
        handleClose();
        dispatch(setArchiveEvaId(null));
    };

    useEffect(() => {
        if ((publishProtocols && evaProtocolsData) || publishData) {
            const hasTitleChanged = publishData?.title !== title;
            const hasDescriptionChanged = publishData?.description !== description;
            const hasTestModeChanged = publishData?.isTestMode !== isTestMode;
            const hasMetaMarkersChanged = !compareMetaMarkers(evaProtocolsData, publishProtocols);
            if (hasMetaMarkersChanged || hasTitleChanged || hasDescriptionChanged || hasTestModeChanged) {
                setEvaPublished(false);
            } else {
                setEvaPublished(true);
            }
        }
        dispatch(setEvaTitle(title));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publishProtocols, evaProtocolsData, title, publishData, description, isTestMode]);

    const inputElement = document.querySelector('.title_input') as HTMLInputElement;

    inputElement?.addEventListener('focus', function () {
        this.placeholder = '';
    });

    inputElement?.addEventListener('blur', function () {
        this.placeholder = `PROTOCOL#${
            (protocolIndex || protocolIndex === 0) && typeof protocolIndex === 'number'
                ? protocolIndex + 1
                : evaProtocolsData.length + 1
        }`;
    });

    const { data: isUserAdmin } = useQuery(['checkUserPermission', organisationId], () => {
        try {
            return checkUserPermission(organisationId as string);
        } catch (e) {
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    useEffect(() => {
        // This is used when we have only draft eva
        const fetchEvaData = async () => {
            setIsLoading(true);
            const publishedEva = await getEvaByOrganisationId(organisationId, EvaType.publish);

            if (publishedEva.isPublished) {
                setPublishData(publishedEva);
            }

            if (publishedEva.id !== null && publishedEva.isPublished) {
                setPublishedEvaExist(true);
            }

            const eva =
                archive && existingEvaId
                    ? await getEvaByEvaId(existingEvaId, EvaType.draft)
                    : await getEvaByOrganisationId(organisationId, EvaType.draft);

            if (eva.id !== null) {
                dispatch(getEvaId(eva.id));
                setTitle(eva.title || '');
                setIsTestMode(eva.isTestMode);

                dispatch(setUpdateData(eva?.lastUpdatedAt || ''));
                setDescription(eva.description || '');
                const metaMarkersData = eva?.metaMarkers.map((item: MetaMarkerItem, index: number) => {
                    // Check if protocolType is an error object, set "Custom", otherwise use the default value
                    const protocolType =
                        typeof item.protocolType === 'object' && 'error' in item.protocolType
                            ? 'Custom'
                            : item.protocolType;
                    return {
                        ...item,
                        protocolType, // Use the updated protocolType
                        indexNum: index,
                    };
                });

                dispatch(setDraftProtocols(metaMarkersData));

                dispatch(
                    setEvaProtocolsData(
                        eva?.metaMarkers?.slice(
                            0,
                            subscription?.productName === SubScriptionType.SHIELD
                                ? 10
                                : subscription?.productName === SubScriptionType.OVERSIGHT
                                ? 40
                                : eva?.metaMarkers.length,
                        ) || [],
                    ),
                );

                setGradients(eva.gradients);
                setArchive(eva.isEvaArchived);
                dispatch(getEvaId(eva.id));
                setCreationMethod(eva.creationMethod);
                setIsRestarted(eva.isRestarted);
                setPublishedAt(eva.publishedAt);
                if (sliderRef.current && !eva.isRestarted) {
                    sliderRef.current.slickNext();
                }
            }
            setIsLoading(false);
        };

        if (existingEvaId !== '') {
            fetchEvaData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationId, evaId, subscription]);

    useEffect(() => {
        if (
            (!isPublishedEvaModel || isPublishedEvaModel) &&
            sliderRef.current &&
            existingEvaId &&
            evaProtocolsData.length > 0 &&
            !isRestarted
        ) {
            sliderRef.current.slickNext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPublishedEvaModel, existingEvaId, sliderRef.current]);

    const handleValidation = (type: string, value: string | boolean) => {
        setValidationErrorMessage((prevState) => ({
            ...prevState,
            [type]: typeof value === 'boolean' ? value : !value?.toString().trim(),
        }));
    };

    const validateTitleDescription = () => {
        handleValidation('title', title);
        handleValidation('description', description);
    };
    const validatePopOverItems = () => {
        handleValidation('metaMarker', metaMarkerValue);
        handleValidation('weight', weight);
        const nuanceValidation = nuancesWithThreshold.some((value) => value.nuance.trim() === '');
        handleValidation('nuance', nuanceValidation);
    };

    const handleDlClick = (event: React.MouseEvent<HTMLElement>, index?: number | null) => {
        setIsCreateMetaMarkerOpen(isCreateMetaMarkerOpen === event?.currentTarget ? null : event.currentTarget);

        dispatch(setSaveConfirmModal(false));
        if (isMetricsMode && index && evaProtocolsData.length >= index) {
            dispatch(setOpenLeftSidePanel('ProtocolList'));
            dispatch(setProtocolEditMode(true));
        } else {
            if (index == null || index == undefined) {
                dispatch(setOpenLeftSidePanel('ProtocolList'));
                setTimeout(() => {
                    dispatch(setProtocolEditMode(true));
                }, 100);
                dispatch(setProtocolIndex(null));
            }

            if (index != null) {
                dispatch(setOpenLeftSidePanel('ProtocolList'));
            }
        }

        dispatch(setAddNewProtocol(null));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hasMetaMarker =
        evaProtocolsData?.length && evaProtocolsData.every((item: MetaMarkerItem) => item.metaMarker.trim() !== '');

    const allMetaMarkersHaveTwoNonEmptyNuances =
        evaProtocolsData.length &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        evaProtocolsData.every((metaMarker: MetaMarkerItem) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const nonEmptyNuances = metaMarker.nuances.filter((nuance: Nuances) => {
                return nuance.nuance.trim() !== '';
            });
            return nonEmptyNuances.length >= 2;
        });

    // Disable the "Save Draft" button if there is no metaMarker and not at-least two nuance in nuances
    const isPublishEnable = hasMetaMarker && allMetaMarkersHaveTwoNonEmptyNuances;

    useEffect(() => {
        if (isProtocolEditMode && currentSlide === 0) {
            setIsCreateMetaMarkerOpen(null);
        }
    }, [isProtocolEditMode, currentSlide]);

    const handleFocus = () => {
        if (title !== '' && description !== '') {
            setIsIconsShow(true);
        }
    };

    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            if ((generatedEva !== 'cloud' || evaId) && (!isRestarted || creationMethod === EvaCreationMethod.Blank)) {
                if (title !== '' && description !== '') {
                    if (event.deltaY > 0) {
                        // Scroll down
                        sliderRef.current?.slickNext();
                    }
                }
            }
        };

        const keyDownHandler = (event: KeyboardEvent) => {
            if (currentSlide == 0 && event.key === 'Enter') {
                validateTitleDescription();
            }

            if (currentSlide == 1 && event.key === 'Enter') {
                validatePopOverItems();
            }
            if (
                metaMarkerValue === '' ||
                weight === '' ||
                currentProtocolNuances.filter((value) => value.nuance.trim() !== '').length < 2
            ) {
                return;
            }
            if (isProtocolEditMode) {
                const nuanceValidation = currentProtocolNuances.some((value) => value.nuance === '');
                if (nuanceValidation && event.key === 'Enter') {
                    handleValidation('nuance', nuanceValidation);
                    return;
                }
                if (event.key === 'Enter') {
                    event.preventDefault();
                    handleAdd();

                    dispatch(setProtocolIndex(null));
                    setIsCreateMetaMarkerOpen(null);
                }
            }
        };

        document.addEventListener('wheel', handleWheel);
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('wheel', handleWheel);
            document.removeEventListener('keydown', keyDownHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaProtocolsData, title, description, nuancesWithThreshold, currentSlide]);

    // use for check button disable

    const settings = {
        className: `${
            currentSlide === 0 ? 'slider_one' : 'slider_two'
        } 'screen_slick_wrapper h-full outline-none overflow-hidden'`,
        dots: isProtocolEditMode || currentSlide === 1 ? false : true,
        infinite: false,
        scroll: true,
        speed: 500,
        draggable: false,
        MouseEvent: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'ease',
        accessibility: false,
        waitForAnimate: true,
        height: '100%',
        arrows: false,
        beforeChange: (oldIndex: number, newIndex: number) => setCurrentSlide(newIndex),
        customPaging: () => <button disabled={title.trim() === '' || description.trim() === ''} />,
    };

    useEffect(() => {
        const containerElement = document.querySelector('.marker_blue_box_content');
        if (containerElement !== undefined && containerElement !== null) {
            if (addNuance) {
                containerElement.scrollTop = containerElement.scrollHeight;
                setAddNuance(false);
            }
        }
    }, [addNuance]);

    // only for fetch Number Input after remove any nuances
    useEffect(() => {
        setIsNuanceRemoved(false);
    }, [isNuanceRemoved]);

    const handleAdd = async () => {
        if (!evaId && title !== '' && description !== '') {
            const updatedMetaMarker = [
                ...evaProtocolsData,
                {
                    triggerNumber: triggerNumber,
                    triggerState: triggerIcon,
                    color: getRgbColorCode(protocolThresholdValues),
                    metaMarker: metaMarkerValue.trim(),
                    weight: 1,
                    nuances: [...currentProtocolNuances],
                    protocolType: protocolType,
                    isSelfCategorization: isEnableProtocolType,
                },
            ];
            dispatch(setEvaProtocolsData(updatedMetaMarker));
            setAddMetaMarker(true);
            dispatch(setCurrentProtocolNuances([]));

            dispatch(setOrbitLoader(false));
        } else {
            if (protocolIndex !== null) {
                // Create a new copy of the metaMarker array
                const updatedMetaMarker = [...evaProtocolsData];
                // Update the protocolIndex element in the copied array
                const matchedIdentification = draftProtocols.find((item) => item.indexNum === protocolIndex);

                const protocolIdentification = matchedIdentification
                    ? matchedIdentification.protocolIdentification
                    : null;
                updatedMetaMarker[protocolIndex] = {
                    triggerNumber: parseFloat(triggerNumber.toString()),
                    triggerState: triggerIcon,
                    color: getRgbColorCode(protocolThresholdValues),
                    metaMarker: metaMarkerValue.trim(),
                    weight: 1,
                    nuances: [...currentProtocolNuances],
                    protocolType: protocolType,
                    isSelfCategorization: isEnableProtocolType,
                    isUpdate: true,
                    protocolIdentification: protocolIdentification,
                };
                dispatch(setEvaProtocolsData(updatedMetaMarker));
                setUpdateMetaMarker(true);

                dispatch(setOrbitLoader(false));
            } else {
                // If protocolIndex is clicked, add new marker
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const updatedMetaMarker = [
                    ...evaProtocolsData,
                    {
                        triggerNumber: parseFloat(triggerNumber.toString()),
                        triggerState: triggerIcon,
                        color: getRgbColorCode(protocolThresholdValues),
                        metaMarker: metaMarkerValue.trim(),
                        weight: parseFloat(weight),
                        nuances: [...currentProtocolNuances],
                        protocolType: protocolType,
                        isSelfCategorization: isEnableProtocolType,
                        isUpdate: true,
                        protocolIdentification: null,
                    },
                ];
                // Dispatch the updated meta marker to the Redux store
                dispatch(setEvaProtocolsData(updatedMetaMarker));
                setUpdateMetaMarker(true);
            }
        }

        dispatch(setOrbitLoader(false));
    };

    useEffect(() => {
        if (protocolIndex !== null) {
            // If a markIndex is clicked, update the local state with its data to validate error state

            dispatch(setTriggerNumber(evaProtocolsData[protocolIndex]?.triggerNumber));

            dispatch(setCopyTriggerNumber(evaProtocolsData[protocolIndex]?.triggerNumber));

            dispatch(setProtocolName(evaProtocolsData[protocolIndex]?.metaMarker));
            dispatch(setCopyOfProtocolName(evaProtocolsData[protocolIndex]?.metaMarker));

            setWeight(evaProtocolsData[protocolIndex]?.weight);
            dispatch(setNuancesWithThreshold(evaProtocolsData[protocolIndex]?.nuances));

            dispatch(setTriggerIcon(evaProtocolsData[protocolIndex]?.triggerState));
            dispatch(setCopyTriggerIcon(evaProtocolsData[protocolIndex]?.triggerState));
            dispatch(setCopyOfProtocolType(evaProtocolsData[protocolIndex]?.protocolType));
            dispatch(setProtocolType(evaProtocolsData[protocolIndex]?.protocolType));

            dispatch(
                setEnableProtocolType(
                    evaProtocolsData[protocolIndex]?.isSelfCategorization === undefined
                        ? false
                        : evaProtocolsData[protocolIndex]?.isSelfCategorization,
                ),
            );

            dispatch(
                setCopyEnableProtocolType(
                    evaProtocolsData[protocolIndex]?.isSelfCategorization == undefined
                        ? false
                        : evaProtocolsData[protocolIndex]?.isSelfCategorization,
                ),
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [protocolIndex, evaProtocolsData]);

    const clearStateData = () => {
        dispatch(setTriggerNumber(initialTrigger));
        dispatch(setCurrentProtocolNuances([]));

        dispatch(setProtocolName(''));
        dispatch(setCopyOfProtocolName(''));

        setWeight('1');
        dispatch(setNuancesWithThreshold([]));

        dispatch(setTriggerIcon('>'));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const putEva = async (saveType: EvaType, metaMarker: MetaMarkerItem[]) => {
        if (evaId == null) return;

        try {
            if (saveType === EvaType.draft) {
                setPublishData(null);
            }
            const eva = await updateEva(
                { title, description, metaMarkers: metaMarker, gradients, saveType, isTestMode, blueprintJson: '' },
                organisationId,
                //@ts-ignore
                evaId,
            );

            if (!eva) return;

            // Handle publish case
            if (saveType === EvaType.publish && eva.isPublished) {
                setPublishData(eva);
                setPublishedEvaExist(true);
            }

            // Update state
            setUpdateMetaMarker(false);
            dispatch(getEvaId(eva.id));
            setTitle(eva.title || '');
            setIsTestMode(eva.isTestMode || false);
            setDescription(eva.description || '');

            // Process metaMarkers and update state
            const metaMarkersData =
                eva.metaMarkers?.map((item: MetaMarkerItem, index: number) => {
                    const protocolType =
                        typeof item.protocolType === 'object' && 'error' in item.protocolType
                            ? 'Custom'
                            : item.protocolType;

                    return {
                        ...item,
                        protocolType,
                        indexNum: index,
                    };
                }) || [];

            dispatch(setDraftProtocols(metaMarkersData));
            dispatch(setEvaProtocolsData(metaMarkersData));

            // Set last object and input values
            const lastObject = metaMarkersData[metaMarkersData.length - 1];
            dispatch(setNuancesWithThreshold(lastObject?.nuances));

            setGradients(eva.gradients);

            // Marker Index logic
            if (protocolIndex === null) {
                dispatch(setProtocolIndex(metaMarkersData.length - 1));
            } else {
                if (protocolIndex !== null) {
                    dispatch(setEnableProtocolType(metaMarkersData[protocolIndex]?.isSelfCategorization));
                    dispatch(setCopyEnableProtocolType(metaMarkersData[protocolIndex]?.isSelfCategorization));
                    dispatch(setCopyOfProtocolName(metaMarkersData[protocolIndex]?.metaMarker));
                    dispatch(setProtocolType(metaMarkersData[protocolIndex]?.protocolType));
                    dispatch(setCopyOfProtocolType(metaMarkersData[protocolIndex]?.protocolType));
                    dispatch(setNuancesWithThreshold(metaMarker[protocolIndex]?.nuances));
                }
            }

            setIsLoading(false);
            setUpdateMetricProtocol(true);
            dispatch(setSaveProtocolEvent(null));

            setTimeout(() => {
                setUpdateMetricProtocol(false);
            }, 200);

            // Handle conflict case (status 409)
        } catch (error: any) {
            console.error('Error updating Eva:', error);
            setIsLoading(false);
            dispatch(setFetchError(true));
            setUpdateMetricProtocol(false);
        }
    };

    const addEva = async (saveType: EvaType, data?: MetaMarkerData) => {
        try {
            dispatch(setProtocolType('Custom'));

            if (evaId === null) {
                // Create EVA
                const payload = {
                    title,
                    description,
                    metaMarkers: evaProtocolsData,
                    gradients,
                    saveType,
                    isTestMode,
                    blueprintJson: '',
                    creationMethod: EvaCreationMethod.Blank,
                };

                setAddMetaMarker(false);
                const eva = await createEva(data ? data : payload, organisationId);
                if (eva) {
                    dispatch(getEvaId(eva.id));
                    setTitle(eva.title || '');
                    setIsTestMode(eva.isTestMode || false);
                    setDescription(eva.description || '');
                    dispatch(setEvaProtocolsData(eva.metaMarkers));
                    dispatch(setDraftProtocols(eva.metaMarkers));
                    setGradients(eva.gradients);
                    dispatch(setProtocolType(eva.protocolType));
                    setArchive(eva.isEvaArchived);
                    if (saveType === EvaType.publish) {
                        setPublishedEvaExist(true);
                    }
                    if (protocolIndex === null && isProtocolEditMode) {
                        dispatch(setProtocolIndex(0));
                    }
                }
            }
        } catch (error) {
            dispatch(setFetchError(true));
            console.error('Error in addEva:', error);
        }
    };

    useEffect(() => {
        const updateEva = async () => {
            if (updateMetaMarker) {
                dispatch(setOrbitLoader(true));
                dispatch(setAddProtocolLoading(true));
                await putEva(EvaType.draft, evaProtocolsData);
                dispatch(setAddProtocolLoading(false));
                dispatch(setOrbitLoader(false));
            }
            if (addMetaMarker) {
                await addEva(EvaType.draft);
            }
        };

        updateEva();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addMetaMarker, updateMetaMarker, evaProtocolsData]);

    const handleSubmit = async (saveType: EvaType, data?: MetaMarkerData) => {
        if (evaId === null) {
            // create eva
            dispatch(setTemplateEvaLoading(true));
            await addEva(saveType, data);
            dispatch(setProtocolType('Custom'));
            dispatch(setTemplateEvaLoading(false));
        }
        if (evaId) {
            // update eva
            dispatch(setUpdateEvaLoading(true));
            await putEva(saveType, evaProtocolsData);
            dispatch(setUpdateEvaLoading(false));
            handleClose();
            history.push(`/organisation/${organisationId}/eva`);
        }
        if (saveType === EvaType.publish) setPublishedAt(new Date().toISOString());
    };

    const handleDeleteEva = async () => {
        try {
            if (evaId) {
                await deleteEva(organisationId, evaId);
                setPublishedEvaExist(false);
                remove();
                setTitle('');
                setDescription('');
                dispatch(setEvaProtocolsData([]));
                dispatch(setDraftProtocols([]));
                setArchive(false);
                dispatch(setArchiveEvaId(null));
                //update the archive list if eva is archived then deleted.
                if (archive) store.organisation.deleteArchivedEVA(evaId);
                handleClose();
                dispatch(setArchiveEvaId(null));
            }
        } catch (error) {
            dispatch(setFetchError(true));
        }
    };

    const handleArchiveEva = async () => {
        if (evaId) {
            await archiveEva(evaId);
            store.organisation.setPublishedEVAExist(false);
            setArchive(true);
            if (publishProtocols.length === 0) {
                setEvaSlide(false);
            }
            dispatch(getEvaId(null));
            remove();
            setTitle('');
            setDescription('');
            dispatch(setEvaProtocolsData([]));
            dispatch(setDraftProtocols([]));
        }
    };

    const handleUnarchiveEva = async () => {
        try {
            if (evaId && organisationId) {
                const publishObj: MetaMarkerData = {
                    creationMethod: creationMethod as EvaCreationMethod,
                    description: description,
                    title: title,
                    saveType: EvaType.publish,
                    gradients: gradients,
                    isTestMode: isTestMode,
                    metaMarkers: evaProtocolsData,
                    blueprintJson: '',
                };
                dispatch(setUnarchiveLoading(true));
                const archivedEVAs = await unarchiveEva(organisationId, evaId, publishObj, true);
                dispatch(setUnarchiveLoading(false));
                setIsOpenPublishModal(false);
                store.organisation.setArchivedEVAs(archivedEVAs);
                store.organisation.setPublishedEVAExist(true);
                setEvaPublished(true);
                handleClose();
                dispatch(setArchiveEvaId(null));
            }
        } catch (error) {
            appInsights.trackException({ exception: error as Error });
        }
    };

    const handleEvaRestart = async () => {
        try {
            if (evaId && organisationId) {
                const evaResponse = await eva.restartEva(evaId);
                if (evaResponse) {
                    setCreationMethod(evaResponse.creationMethod);
                    setIsRestarted(true);
                    dispatch(setEvaProtocolsData([]));

                    //going back to the first slide
                    sliderRef.current?.slickPrev();
                }
            }
        } catch (error) {
            appInsights.trackException({ exception: error as Error });
        }
    };

    useEffect(() => {
        if (title.trim() !== '' && description.trim() !== '' && currentSlide == 0) {
            setIsIconsShow(true);
        } else {
            setIsIconsShow(false);
        }
    }, [title, description, currentSlide]);

    const bearer = localStorage.getItem('jwt') ?? undefined;
    const ENV_BASE_URL = process.env.REACT_APP_BACK_END_URL || 'http://localhost:3000';

    const {
        data: evaMetaMarkerData,
        isLoading: isLoadingMetaMarker,
        isFetching: isFetching,
        remove,
    } = useQuery(
        'metaMarkerData',
        async () => {
            const response = await fetch(`${ENV_BASE_URL}/organisation/${organisationId}/eva`, {
                headers: {
                    Authorization: `Bearer ${bearer}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    input: {
                        title,
                        description,
                        metaMarkers: evaProtocolsData,
                        gradients,
                        saveType: EvaType.draft,
                        isTestMode,
                        blueprintJson: '',
                        creationMethod: EvaCreationMethod.Cloud,
                    },
                    organisationId,
                    isCloudApiCall: isMetaMarkerEnabled,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        },

        {
            enabled: isMetaMarkerEnabled,
            onSettled: () => {
                setIsMetaMarkerEnabled(false);
            },
        },
    );

    const handleClickEnter = async () => {
        if (title !== '' && description !== '' && !isRestarted) {
            if (generatedEva === 'cloud') {
                setIsMetaMarkerEnabled(true);
            } else {
                if (evaId) {
                    dispatch(setRestartEvaLoading(true));
                    await putEva(EvaType.draft, evaProtocolsData);
                    dispatch(setRestartEvaLoading(false));
                }
            }
            sliderRef.current?.slickNext();
            if (onboardingTour?.startTour && !onboardingTour.evaMetamarker) {
                setMetaMarkerDialog(true);
            }
        } else if (isRestarted) {
            //if eva is restarted then we need to update the eva based on the creation method
            if (creationMethod === EvaCreationMethod.Blank) {
                sliderRef.current?.slickNext();
            }
            if (creationMethod === EvaCreationMethod.Cloud) {
                sliderRef.current?.slickNext();
                dispatch(setRestartEvaLoading(true));
                await putEva(EvaType.draft, evaProtocolsData);
                dispatch(setRestartEvaLoading(false));
            }
            if (creationMethod === EvaCreationMethod.Template) {
                sliderRef.current?.slickNext();
                dispatch(setRestartEvaLoading(true));
                await putEva(EvaType.draft, evaProtocolsData);
                dispatch(setRestartEvaLoading(false));
            } else {
                sliderRef.current?.slickNext();
            }
        }
    };
    const handleMetaMarker = async (data: string) => {
        setIsOpen(false);
        setEvaSlide(true);
        setGeneratedEva(data);
        sliderRef.current?.slickNext();
    };
    const dispatch = useDispatch();

    useEffect(() => {
        if (evaMetaMarkerData) {
            dispatch(getEvaId(evaMetaMarkerData.id));
            setTitle(evaMetaMarkerData.title || '');
            setIsTestMode(evaMetaMarkerData.isTestMode);
            setDescription(evaMetaMarkerData.description || '');
            dispatch(setEvaProtocolsData(evaMetaMarkerData.metaMarkers || []));
            setGradients(evaMetaMarkerData.gradients);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaMetaMarkerData]);

    const createEvaMetaMarker = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (currentSlide == 0) {
            validateTitleDescription();
        }
        if (currentSlide == 1) {
            validatePopOverItems();
        }

        if (currentProtocolNuances?.filter((value) => value?.nuance?.trim() !== '').length < 2) {
            dispatch(setNuancesValidate(true));
            return;
        } else {
            dispatch(setNuancesValidate(false));
        }

        if (metaMarkerValue.trim() === '') {
            dispatch(setMetaMarkerValidation(true));
            dispatch(setAddProtocolLoading(false));
            return;
        } else {
            dispatch(setMetaMarkerValidation(false));
        }

        if (isProtocolEditMode) {
            const nuanceValidation = currentProtocolNuances.some((value) => value.nuance === '');
            if (nuanceValidation) {
                handleValidation('nuance', nuanceValidation);
                return;
            }
            handleAdd();
        }

        setIsCreateMetaMarkerOpen(null);
    };

    const deleteEvaMetaMarker = async (metamarkerId: number | null, saveType: EvaType) => {
        if (metamarkerId || metamarkerId === 0) {
            const newArray = [...evaProtocolsData];
            const deletedMetaMarker = newArray[metamarkerId];
            newArray.splice(metamarkerId, 1);
            dispatch(setEvaProtocolsData(newArray));

            setIsCreateMetaMarkerOpen(null);
            try {
                await putEva(saveType, newArray);
                if (evaId && deletedMetaMarker)
                    metric.updateProtocolStatusOnDelete(evaId, deletedMetaMarker.metaMarker);
            } catch (error) {
                appInsights.trackException({ exception: error as Error });
            }
        } else {
            setValidationErrorMessage({
                title: false,
                description: false,
                metaMarker: false,
                weight: false,
                nuance: false,
            });
            setIsCreateMetaMarkerOpen(null);
            clearStateData();
        }
        dispatch(setCheCkNuancesLeg(false));
        dispatch(setOrbitLoader(false));
        dispatch(setProtocolIndex(null));
        dispatch(setDeleteProtocol(false));
        dispatch(setProtocolEditMode(false));
        const hasMetaMarkersChanged = !compareMetaMarkers(evaProtocolsData, publishProtocols);
        if (hasMetaMarkersChanged) {
            setEvaPublished(false);
        }
    };

    useEffect(() => {
        if (isDeleteProtocol) {
            deleteEvaMetaMarker(protocolIndex, EvaType.draft);
            dispatch(setProtocolIndex(null));
            dispatch(setDeleteProtocol(false));
            dispatch(setProtocolEditMode(false));
        }

        if (SaveProtocolEvent !== null && typeof SaveProtocolEvent === 'object') {
            const event = SaveProtocolEvent as React.MouseEvent<HTMLElement, MouseEvent>;
            createEvaMetaMarker(event);
        }
        if (typeof addNewProtocol === 'object' && addNewProtocol !== null) {
            const event = addNewProtocol as React.MouseEvent<HTMLElement, MouseEvent>;
            handleDlClick(event, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleteProtocol, SaveProtocolEvent, addNewProtocol]);

    const handleDescriptionClick = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (title !== '' && description !== '') {
                if (generatedEva === 'cloud') {
                    setIsMetaMarkerEnabled(true);
                }
                sliderRef.current?.slickNext();
            }
        }
    };

    const confirmSave = () => {
        if (checkConfirmationScreen) {
            dispatch(setIsChangeProtocolData(false));
            dispatch(setProtocolEditMode(false));
        } else {
            dispatch(setProtocolIndex(null));
            dispatch(setIsChangeProtocolData(false));
            dispatch(setProtocolEditMode(false));
            dispatch(setOpenLeftSidePanel(''));
        }
        dispatch(setMetaMarkerValidation(false));
        dispatch(setProtocolErrorMessage(null));
        dispatch(setApiNuances([]));
        dispatch(setChangeNuancesScreen(false));
    };

    const handleCloseTourDialog = () => {
        if (metaMarkerDialog) {
            setMetaMarkerDialog(false);
            setTestModeDialog(true);
            updateOnboardingTour(true, 'evaMetamarker');
        } else {
            setTestModeDialog(false);
            updateOnboardingTour(true, 'evaTestMode');
            updateOnboardingTour(false, 'startTour');
        }
    };

    const { isLoading: isLoadingArchive, refetch } = useQuery(
        ['archive-current-eva', organisationId],
        async () => {
            try {
                const data = await organisation.archiveCurrentEva(organisationId);
                if (data.status === 200) {
                    setPublishedEvaExist(false);
                    dispatch(getEvaId(null));
                    dispatch(setArchiveEvaId(null));
                }
                return data;
            } catch (e) {
                console.error(e);
                if (e instanceof Error) {
                    appInsights.trackException({ error: e });
                }
            }
        },
        {
            enabled: false,
        },
    );

    const AddArchived = () => {
        refetch();
    };

    const handleActionButtonClick = () => {
        const index = nuancesWithThreshold.findIndex((item) => item.nuance === newNuances.oldNuances);
        if (index !== -1) {
            // Update the existing protocol
            const updatedValues = [...nuancesWithThreshold];
            updatedValues[index] = { nuance: newNuances.nuance, threshold: newNuances.threshold };
            return updatedValues;
        } else {
            const newProtocol = { nuance: newNuances.nuance, threshold: newNuances.threshold };
            dispatch(setNuancesWithThreshold([...nuancesWithThreshold, newProtocol]));
        }
    };

    useEffect(() => {
        const Loading = isLoadingMetaMarker || isFetching || templateEvaLoading || isLoading || restartEvaLoading;
        dispatch(setBluePrintEvaLoading(Loading));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingMetaMarker, isFetching, templateEvaLoading, isLoading, restartEvaLoading]);
    // title and description hide show edit icon
    const handleClickOutside = (event: MouseEvent) => {
        if (
            titleRef.current &&
            !titleRef.current.contains(event.target as Node) &&
            descriptionRef.current &&
            !descriptionRef.current.contains(event.target as Node)
        ) {
            setEditTitle(false);
            setEditDescription(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleTitleFocus = () => {
        setTitleFocus(true);
        setDescriptionFocus(false);
        setTimeout(() => {
            titleInputRef.current?.focus(); // Ensure the input gets focus after state update
        }, 0);
    };

    const handleDescriptionFocus = () => {
        setDescriptionFocus(true);
        setTitleFocus(false);
        setTimeout(() => {
            descriptionInputRef.current?.focus(); // Ensure the input gets focus after state update
        }, 0);
    };

    return (
        <>
            {/* Archive EVA Confirmation Modal From create a blueprint  */}
            {!isLoading && (
                <EvaDialog
                    open={isCreateEvaFromBlueprints}
                    setClose={() => !isLoadingArchive && dispatch(SetIsCreateEvaFromBlueprints(false))}
                    onConfirm={AddArchived}
                    disabled={false}
                    isConfirmDisabled={isLoadingArchive}
                    title={t('create_blueprint')}
                    content={<div dangerouslySetInnerHTML={{ __html: t('archive_blueprint_warning', { title }) }} />}
                    confirmButtonText={t('confirm')}
                    buttonVariant='bberryBlue'
                />
            )}

            <EvaDialog
                open={isOpenRestartModal}
                setClose={setIsOpenRestartModal}
                onConfirm={handleEvaRestart}
                disabled={false}
                title={t('restart_blueprint')}
                content={<div dangerouslySetInnerHTML={{ __html: t('restart_delete_warning', { title }) }} />}
                confirmButtonText={t('restart')}
                buttonVariant='primaryDarkBlue'
            />
            <EvaDialog
                open={isOpenPublishModal}
                setClose={unarchiveLoading ? () => noop() : () => setIsOpenPublishModal(false)}
                onConfirm={handleUnarchiveEva}
                disabled={!isPublishEnable}
                title={t('set_as_main')}
                content={<div dangerouslySetInnerHTML={{ __html: t('replace_blueprint_warning', { title }) }} />}
                confirmButtonText={
                    unarchiveLoading ? <div className='file_loading report_loader' /> : t('btnNames.publish')
                }
                isCloseModal={true}
                buttonVariant='bberryBlue'
            />
            <EvaDialog
                open={isOpenDeleteModal}
                setClose={setIsOpenDeleteModal}
                onConfirm={handleDeleteEva}
                disabled={false}
                title={t('delete_eva')}
                content={<div dangerouslySetInnerHTML={{ __html: t('delete_warning', { title }) }} />}
                confirmButtonText={t('btnNames.delete')}
                buttonVariant='rejectRed'
            />
            <Dialog fullScreen open={open} onClose={handleClose} className='main_modal'>
                <div
                    ref={modalRef}
                    id='blue_print_modal'
                    className='relative flex flex-col w-full bg-dark-blue-radial z-10 blue_print_box'
                >
                    {!existingEvaId && !evaSlide ? (
                        <EvaTemplate
                            closeModal={handleClose}
                            handleChangeSlide={() => {
                                setEvaSlide(true);
                                //to go to protocol page when user click on next button
                                setCurrentSlide(1);
                                sliderRef.current?.slickNext();
                            }}
                            handleMetaMarker={handleMetaMarker}
                            isLoading={isLoadingMetaMarker}
                            handleSubmit={handleSubmit}
                            organisationId={organisationId}
                        />
                    ) : (
                        <>
                            <div
                                className={`${
                                    bluePrintLoading || updateEvaLoading ? 'disable_overly ' : ''
                                } blue_print_top`}
                            >
                                {evaSlide || existingEvaId ? (
                                    <>
                                        {currentSlide === 1 && (
                                            <div className={`  ${classes.headerTitle} eva_top_head`}>
                                                <div className='eva_content'>
                                                    <div ref={titleRef} onBlur={() => setEditTitle(false)} tabIndex={0}>
                                                        <EvaTitleDescription
                                                            input={title}
                                                            size='base '
                                                            setInput={setTitle}
                                                            handleValidation={handleValidation}
                                                            type='title'
                                                            maxLength={50}
                                                            className='eva_top_title'
                                                            font='medium'
                                                            fontWeight='semibold'
                                                            edit={editTitle}
                                                            toggleEdit={toggleEditTitle}
                                                        />
                                                    </div>
                                                    <div
                                                        ref={descriptionRef}
                                                        onBlur={() => setEditDescription(false)}
                                                        tabIndex={0}
                                                    >
                                                        <EvaTitleDescription
                                                            input={description}
                                                            size='sm eva_top_disc'
                                                            setInput={setDescription}
                                                            handleValidation={handleValidation}
                                                            type='description'
                                                            maxLength={150}
                                                            font='small'
                                                            className='eva_top_disc'
                                                            fontWeight='normal'
                                                            edit={editDescription}
                                                            toggleEdit={toggleEditDescription}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : null}

                                {currentSlide === 1 ? (
                                    <EvaHeader
                                        currentSlide={currentSlide}
                                        isArchived={isArchived}
                                        isTestMode={evaluateMode}
                                        setIsTestMode={setEvaluateMode}
                                        publishedAt={publishedAt}
                                        archive={archive}
                                        isUserAdmin={isUserAdmin}
                                        handleArchiveEva={handleArchiveEva}
                                        handleUnarchiveEva={handleUnarchiveEva}
                                        setIsOpenRestartModal={setIsOpenRestartModal}
                                        setIsOpenDeleteModal={setIsOpenDeleteModal}
                                        setIsOpenPublishModal={setIsOpenPublishModal}
                                        handleSubmit={handleSubmit}
                                        setEvaPublished={setEvaPublished}
                                        isPublishEnable={isPublishEnable}
                                        isEvaPublished={isEvaPublished}
                                        setLabelShow={setLabelShow}
                                        labelShow={labelShow}
                                        handleClose={handleClose}
                                        isPublished={isPublished}
                                        setEditUserResponse={setEditUserResponse}
                                    />
                                ) : null}
                            </div>

                            {evaSlide || existingEvaId ? (
                                <div className='relative h-full blue_print_eva'>
                                    <Slider ref={sliderRef} {...settings}>
                                        {/* First Slider */}
                                        <div className='h-full overflow-hidden eva-first-slider-wrapper outline-none'>
                                            {!isLoading ? (
                                                <>
                                                    <div
                                                        ref={divRef}
                                                        onFocus={handleFocus}
                                                        tabIndex={0} // Make the div focusable
                                                        className='flex flex-1 justify-center items-center flex-col eva-first-slider h-full outline-none'
                                                    >
                                                        <div className='eva_before_img'>
                                                            <img src={planet} />
                                                        </div>
                                                        <Box
                                                            className='eva-first-slider-planet absolute text-center z-10'
                                                            width={description.length > 50 ? 280 : 235}
                                                            padding={description.length > 50 ? '8px 12px' : '8px'}
                                                        >
                                                            <Box className='grid'>
                                                                {/* Title Section */}
                                                                <div
                                                                    className='create_eva_input title'
                                                                    onClick={() => setDescriptionFocus(false)}
                                                                >
                                                                    {titleFocus ? (
                                                                        <input
                                                                            ref={titleInputRef}
                                                                            type='text'
                                                                            maxLength={50}
                                                                            onBlur={() => {
                                                                                setTitleFocus(false);
                                                                                handleValidation('title', title);
                                                                            }}
                                                                            onChange={(e) => setTitle(e.target.value)}
                                                                            value={title}
                                                                        />
                                                                    ) : (
                                                                        <p
                                                                            onClick={handleTitleFocus}
                                                                            className={
                                                                                title
                                                                                    ? 'title_text'
                                                                                    : 'placeholder_text'
                                                                            }
                                                                        >
                                                                            {title || t('title_max')}
                                                                            {!title && <span>*</span>}
                                                                        </p>
                                                                    )}

                                                                    {validationErrorMessage.title &&
                                                                        title.length === 0 && (
                                                                            <ErrorMessage message={t('enter_title')} />
                                                                        )}
                                                                </div>

                                                                {/* Description Section */}
                                                                <div
                                                                    className='create_eva_input description'
                                                                    onClick={() => setTitleFocus(false)}
                                                                >
                                                                    {descriptionFocus ? (
                                                                        <textarea
                                                                            ref={descriptionInputRef}
                                                                            id='metaMarker-description'
                                                                            maxLength={150}
                                                                            onBlur={() => {
                                                                                setDescriptionFocus(false);
                                                                                handleValidation(
                                                                                    'description',
                                                                                    description,
                                                                                );
                                                                            }}
                                                                            onChange={(e) =>
                                                                                setDescription(e.target.value)
                                                                            }
                                                                            value={description}
                                                                            onInput={(e) => {
                                                                                const textarea =
                                                                                    e.target as HTMLTextAreaElement;
                                                                                textarea.style.height = 'auto'; // Reset height to recalculate
                                                                                textarea.style.height = `${
                                                                                    !description
                                                                                        ? 18
                                                                                        : textarea.scrollHeight
                                                                                }px`; // Adjust height based on content
                                                                            }}
                                                                            onFocus={(e) => {
                                                                                const textarea =
                                                                                    e.target as HTMLTextAreaElement;
                                                                                textarea.style.height = 'auto'; // Reset height to recalculate
                                                                                textarea.style.height = `${
                                                                                    !description
                                                                                        ? 18
                                                                                        : textarea.scrollHeight
                                                                                }px`; // Adjust height when focused
                                                                            }}
                                                                            style={{
                                                                                overflow: 'hidden',
                                                                                resize: 'none',
                                                                            }} // Prevent manual resize and hide scrollbars
                                                                        />
                                                                    ) : (
                                                                        <p
                                                                            onClick={handleDescriptionFocus}
                                                                            className={
                                                                                description
                                                                                    ? 'description_text'
                                                                                    : 'placeholder_text'
                                                                            }
                                                                        >
                                                                            {description || t('description_max')}
                                                                            {!description && <span>*</span>}
                                                                        </p>
                                                                    )}

                                                                    {validationErrorMessage.description &&
                                                                        description.length === 0 && (
                                                                            <ErrorMessage
                                                                                message={t('enter_description')}
                                                                            />
                                                                        )}
                                                                </div>

                                                                {/* Submit Button */}
                                                                <button className='submit_btn' type='submit'>
                                                                    {t('btnNames.submit')}
                                                                </button>
                                                            </Box>
                                                        </Box>

                                                        {isIconsShow && (
                                                            <>
                                                                <div className='relative z-10'>
                                                                    <ArrowIcon
                                                                        onClick={handleClickEnter}
                                                                        className='bg-lightblue text-lightblue mx-auto h-4 w-5 mt-1 p-1 rounded cursor-pointer'
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className='absolute left-0 right-0 mx-auto'>
                                                        <p className='flex justify-center font-medium text-lightblue eva-footer'>
                                                            {t('give_description')}
                                                        </p>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='flex justify-center items-center eva_circle  w-100 h-100'>
                                                    <AnimatedCircle />
                                                </div>
                                            )}
                                        </div>

                                        {/* Second Slider */}
                                        <div className='h-full overflow-hidden outline-none'>
                                            <ClickAwayListener onClickAway={handleCloseTourDialog}>
                                                <TourDialog
                                                    open={metaMarkerDialog}
                                                    handleClose={handleCloseTourDialog}
                                                    top={200}
                                                    left={100}
                                                    type={'evaMetamarker'}
                                                    title={t('home.tour.metamarker.title')}
                                                    content={
                                                        <div className='space-y-2 text-white text-opacity-75 mt-4 text-xs'>
                                                            <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                                                                <p className='whitespace-nowrap'>{t('trigger')}</p>
                                                            </div>
                                                            <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                                                                <p className='whitespace-nowrap'> {t('metamarker')} </p>
                                                            </div>
                                                            <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                                                                <p className='whitespace-nowrap'>{t('weight')}</p>
                                                            </div>
                                                            <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                                                                <p className='whitespace-nowrap'>{t('nuances')}</p>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    className='flex space-x-2 mt-5'
                                                                    size='oval'
                                                                    variant='fillMetamarker'
                                                                >
                                                                    <img
                                                                        src={fillMetamarkerIcon}
                                                                        alt='fill_metamarker'
                                                                    />
                                                                    <p>{t('fillMetamarker')}</p>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </ClickAwayListener>
                                            <ClickAwayListener onClickAway={handleCloseTourDialog}>
                                                <TourDialog
                                                    open={testModeDialog}
                                                    handleClose={handleCloseTourDialog}
                                                    top={-40}
                                                    left={130}
                                                    type={'evaTestMode'}
                                                    title={'Test mode'}
                                                    content={
                                                        <div className='space-y-2 text-white text-opacity-75 mt-4 text-xs'>
                                                            <p>
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: t('testBlueprint'),
                                                                    }}
                                                                />
                                                            </p>
                                                        </div>
                                                    }
                                                />
                                            </ClickAwayListener>
                                            <div className='flex flex-1 justify-center items-center h-full outline-none create_eva_main'>
                                                <>
                                                    <div id='solar-system' className='saturn'>
                                                        {bluePrintLoading ? (
                                                            <div className='blue_print_modal_loading'>
                                                                <CustomLoader />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <CreateEvaOrbit
                                                                    handleDlClick={handleDlClick}
                                                                    setHoldSidebar={setHoldSidebar}
                                                                    labelShow={labelShow}
                                                                    currentSlide={currentSlide}
                                                                    subscription={subscription}
                                                                />

                                                                <div className='orbit-center-img'>
                                                                    <AnimatedCircle />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </>

                                                <svg className='lines-container' />
                                                <div>
                                                    <CtrlLeftSidePanel
                                                        disableArrow={editUserResponse}
                                                        setDisableArrow={setEditUserResponse}
                                                    />
                                                </div>
                                                <RightSidePanel
                                                    protocol={currentProtocolNuances}
                                                    protocolName={metaMarkerValue}
                                                    setEvaluateHint={setEvaluateHint}
                                                    setActionType={setActionType}
                                                    setNewProtocol={setNewNuances}
                                                    setEditUserResponse={setEditUserResponse}
                                                    handleDlClick={handleDlClick}
                                                    protocolUpdated={updateMetricProtocol}
                                                    currentSlide={currentSlide}
                                                    genMetricProtocol={handleGenMetricProtocol}
                                                />
                                                {/* evaluet response hint */}
                                                {evaluateHint && isEvaluateMode && (
                                                    <EvaluateHint
                                                        actionType={actionType}
                                                        newNuances={newNuances}
                                                        evaluateHint={evaluateHint}
                                                        handleActionButtonClick={handleActionButtonClick}
                                                        protocolName={metaMarkerValue}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            ) : null}
                        </>
                    )}

                    {errors.map((error, index) => (
                        <div key={index} className='file-error-box'>
                            <div className='file-error'>
                                <FileErrorIcon />
                                <p className='file-error-msg'>{error}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </Dialog>

            <EvaDialog
                open={saveConfirmModal}
                setClose={() => dispatch(setSaveConfirmModal(false))}
                onConfirm={confirmSave}
                disabled={false}
                title={t('protocol_exit')}
                content={<div dangerouslySetInnerHTML={{ __html: t('close_without_saving', { metaMarkerValue }) }} />}
                confirmButtonText={t('btnNames.yes')}
                buttonVariant='primaryDarkBlue'
            />

            <EvaDialog
                open={openModal}
                setClose={() => dispatch(setRemoveArchiveEvaIdConfirmation(false))}
                onConfirm={RemoveArchiveIdConfirm}
                disabled={false}
                title={t('blueprint_exit')}
                content={<div dangerouslySetInnerHTML={{ __html: t('blueprint_exit_description', { evaTitle }) }} />}
                confirmButtonText={t('btnNames.yes')}
                buttonVariant='primaryDarkBlue'
            />
        </>
    );
};

export default CreateEvaModel;
