import { appInsights } from '../../AppInsights';
import { EvaluateProtocol, Nuances } from '../../components/organisation/copilotOrganisation/eva/types';
import { CreateReportDto } from '../../components/organisation/copilotOrganisation/evaAnalytics/components/analyticsChart/types';
import { get, post, postFile, put } from '../../utils/network';

export class EvaService {
    getTemplatesCategories() {
        try {
            return get(`/eva/templates-categories`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getBlueprintsByCategory(orgId: string, category: string) {
        try {
            return get(`/eva/templates-blueprints?org=${orgId}&category=${category}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getBlueprintByCategoryIdAndBlueprintId(orgId: string, blueprintId: string) {
        try {
            return get(`/eva/templates-blueprint/${blueprintId}?org=${orgId}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getEvaGraph(evaId: string, category: string, dateRange: string) {
        try {
            return get(`/eva/${evaId}/graph?category=${category}&drilldateparam=${dateRange} `);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getEvaProtocolLog(evaId: string, category: string, timeZoneOffset: string, drilledDate: string | null) {
        try {
            const queryParams = new URLSearchParams({
                category,
                timeZoneOffset,
            });

            if (drilledDate) {
                queryParams.append('drilledDate', drilledDate);
            }
            return get(`/eva/${evaId}/protocol-trends?${queryParams.toString()}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getEvaProtocolList(evaId: string, evaType: string, type: string[], sortBy: string, search?: string) {
        try {
            const lowercasedSortBy = sortBy.toLowerCase();

            return get(
                `/eva/${evaId}/protocol-list?evaType=${evaType}&type=${type}&sortBy=${lowercasedSortBy}&search=${search}`,
            );
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getEvaLog(evaId: string, sort: { sortBy: string; order: string }, search: string, page: string) {
        try {
            const queryParams = new URLSearchParams({
                sortBy: sort.sortBy.toLowerCase(),
                search,
                order: sort.order.toLowerCase(),
                page,
            });
            return get(`/eva/${evaId}/log?${queryParams.toString()}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }
    getProtocolInsights(evaId: string) {
        try {
            return get(`/eva/${evaId}/protocol-insights`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getLatestEvaUpdate(evaId: string) {
        try {
            return get(`/eva/${evaId}/latest-update`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getEvaCtrlLog = async (evaId: string, page: number) => {
        try {
            return get(`/eva/${evaId}/ctrl-log?page=${page}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    restartEva = async (evaId: string) => {
        try {
            return put(`/eva/${evaId}/restart`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    getEvaUserEngagement = async (evaId: string) => {
        try {
            return get(`/eva/${evaId}/user-engagement`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    makeEvaTestMode = async (input: { isEvaTestMode: boolean }, evaId: string) => {
        try {
            const response = await put(`/eva/${evaId}/makeEvaTestMode`, input);
            return response;
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    getEvaCsvLog(evaId: string, downLoadType: string) {
        try {
            return get(`/eva/${evaId}/csv?category=${downLoadType}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getProtocolTypes = async (input: { metaMarker: string; nuances: Nuances[] }) => {
        try {
            return post(`/eva/get-protocol-types`, input);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    putEvaTriggerField = async () => {
        try {
            return put(`/eva/put-eva-trigger`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    getAnalyticsRisk = async (evaId: string) => {
        try {
            return get(`/eva/${evaId}/analytics-risk`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    simulateEva = async (
        evaId: string,
        input: {
            scenarioType: string;
            user1: string | null;
            user2: string | null;
            conversationType: string;
            userMood1: string;
            userMood2: string;
            keywords: string[];
            protocolName: string;
            protocol: { nuance: string; threshold: number }[];
            variation: boolean;
        },
    ) => {
        try {
            return post(`/eva/${evaId}/simulate`, input);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    getAction = async (
        evaId: string,
        input: {
            posneg: boolean;
            protocolName: string;
            protocol: { nuance: string; threshold: number }[];
            input: string;
        },
    ) => {
        return post(`/eva/${evaId}/action`, input);
    };

    getUserResponseScore = async (evaId: string, input: string, protocolName: string) => {
        return post(`/eva/${evaId}/user-response-score`, { input, protocolName });
    };

    getCronDataEvaEventTrigger = async () => {
        try {
            return get(`/cron/existing-trigger-json-eva-event-trigger`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    getCronDataSnapShots = async () => {
        try {
            return get(`/cron/existing-trigger-json-snapshots`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    getCronDataEvaEvents = async () => {
        try {
            return get(`/cron/existing-trigger-json-eva-events`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    getCronDataEvaVersions = async () => {
        try {
            return get(`/cron/existing-trigger-json-eva-versions`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    getCronDataEva = async () => {
        try {
            return get(`/cron/existing-trigger-json-eva`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    getCronDataReports = async () => {
        try {
            return get(`/cron/existing-trigger-json-reports`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    getCronData = async () => {
        try {
            return get(`/cron/existing-trigger-entry`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    postNuancesData(blob: FormData, evaId: string) {
        if (blob) {
            try {
                return postFile(`/eva/${evaId}/protocol-mapping`, blob);
            } catch (err) {
                if (err instanceof Error) {
                    appInsights.trackException({ exception: err });
                }
            }
        }
    }

    getScoreofConversation = async (blueprint: string, trigger: string, input: string) => {
        try {
            return post(`/eva/get-score`, { blueprint, trigger, input });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    };

    createReport(evaId: string, input: CreateReportDto) {
        try {
            return post(`/eva/${evaId}/create-report`, { input });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getReportSummary(evaId: string, sortVal: string, search: string, page: string) {
        try {
            const queryParams = new URLSearchParams({
                sortBy: sortVal.toLowerCase(),
                search,
                page,
            });
            return get(`/eva/${evaId}/report-summary?${queryParams.toString()}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getReportSummaryById(evaId: string, sessionId: string) {
        try {
            return get(`/eva/${evaId}/${sessionId}/report-by-session-id`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getReportVersionHistory(evaId: string, sessionId: string, sortVal: string, page: string) {
        try {
            const queryParams = new URLSearchParams({
                sortBy: sortVal.toLowerCase(),
                page,
            });
            return get(`/eva/${evaId}/${sessionId}/report-version-history?${queryParams.toString()}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    DeleteHistory(evaId: string, sessionIds: string[], isParentReport: boolean) {
        try {
            return put(`/eva/${evaId}/delete-version-history`, { sessionIds, isParentReport });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }
}
