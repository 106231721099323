/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { noop, set } from 'lodash';
import moment from 'moment';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { FaAngleRight } from 'react-icons/fa6';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import ReactSlider from 'react-slider';
import { Tooltip } from 'react-tooltip';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ReactComponent as HorizontalLine } from '../../../../../../src/assets/icons/dashline.svg';
import { ReactComponent as DownArrow } from '../../../../../../src/assets/icons/DownArrow.svg';
import { ReactComponent as EditBtn } from '../../../../../../src/assets/icons/Edit_Icon.svg';
import { ReactComponent as MetricsIcon } from '../../../../../../src/assets/icons/icon-metrics.svg';
import { ReactComponent as LeftArrows } from '../../../../../../src/assets/icons/leftArrow.svg';
import { ReactComponent as CheckboxIcon } from '../../../../../../src/assets/icons/ProtocolType_checkbox_Icon.svg';
import { ReactComponent as FlagIcon } from '../../../../../../src/components/organisation/copilotOrganisation/eva/components/img/icon-flag.svg';
import { appInsights } from '../../../../../AppInsights';
import { ReactComponent as ActiveFileUploadIcon } from '../../../../../assets/icons/active_file_Upload.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/Close_icon.svg';
import { ReactComponent as CsvFile } from '../../../../../assets/icons/Csv_Icon.svg';
import { ReactComponent as DocFile } from '../../../../../assets/icons/doc_Icon.svg';
import { ReactComponent as FileUploadIcon } from '../../../../../assets/icons/file_uplod_icon.svg';
import { ReactComponent as FileUploadIconHover } from '../../../../../assets/icons/file_uplod_iconHover.svg';
import { ReactComponent as HtmlFile } from '../../../../../assets/icons/Html_Icon.svg';
import { ReactComponent as CloseIconNuance } from '../../../../../assets/icons/icon-close.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/icon-nuance-trigger-plus.svg';
import { ReactComponent as CloseEye } from '../../../../../assets/icons/icon-purple-eye-closed.svg';
import { ReactComponent as CloseEyeWhite } from '../../../../../assets/icons/icon-white-eye-closed.svg';
import { ReactComponent as JsonFile } from '../../../../../assets/icons/Json_Icon.svg';
import { ReactComponent as LeftArrow } from '../../../../../assets/icons/Left_arrow.svg';
import { ReactComponent as OtherFile } from '../../../../../assets/icons/Other_File_Icon.svg';
import { ReactComponent as PDFIcon } from '../../../../../assets/icons/PDF_Icon.svg';
import { ReactComponent as RefreshIcon } from '../../../../../assets/icons/Refresh_Icon.svg';
import { ReactComponent as ReplaceIcon } from '../../../../../assets/icons/Replace_Icon.svg';
import { ReactComponent as RightArrow } from '../../../../../assets/icons/Right_arrow.svg';
import { ReactComponent as SaveIcon } from '../../../../../assets/icons/Save_icon.svg';
import { ReactComponent as SaveIconHover } from '../../../../../assets/icons/Save_iconHover.svg';
import { ReactComponent as TxtFile } from '../../../../../assets/icons/Txt_Icon.svg';
import { ReactComponent as XlsFile } from '../../../../../assets/icons/Xls_Icon.svg';
import { ReactComponent as XmlFile } from '../../../../../assets/icons/Xml_Icon.svg';
import {
    setAddProtocolLoading,
    setApiNuances,
    setChangeNuancesScreen,
    setCheCkNuancesLeg,
    setConfirmationScreen,
    setCopyEnableProtocolType,
    setCopyOfProtocolName,
    setCopyOfProtocolType,
    setCopyTriggerNumber,
    setCurrentProtocolNuances,
    setDeleteProtocol,
    setEnableProtocolType,
    setEvaluateMode,
    setFileError,
    setIsChangeProtocolData,
    setMetaMarkerValidation,
    setMetricsMode,
    setNuancesValidate,
    setNuancesWithThreshold,
    setOrbitLoader,
    setProtocolEditMode,
    setProtocolErrorMessage,
    setProtocolIndex,
    setProtocolName,
    setProtocolThresholdValues,
    setProtocolType,
    setSaveConfirmModal,
    setSaveProtocolEvent,
    setTriggerIcon,
    setTriggerNumber,
    setUploadFileLoading,
} from '../../../../../Redux/EvaInfo';
import { RootState } from '../../../../../Redux/store';
import { EvaService } from '../../../../../services/eva/eva.service';
import { Button } from '../../../../basic/Button.component';
import { InputWithLength } from '../../../../basic/InputWithLength.component';
import { ReactComponent as IconDelete } from '../components/img/icon-delete.svg';
import { ReactComponent as IconRight } from '../components/img/icon-right.svg';
import { Nuances, ProtocolOptionType, ThumbData } from '../types';
import { convertToThumbData, deepCompareData, formatCamelCase, formatDateToDDMMYYYY } from './createEva/EvaCompare';
import { ErrorComponent } from './error';
import NuancesMapping from './NuancesMapping';
import { OptionData } from './ProtocolTypeList';

import './style.css';
import './slider/slider.css';

const useStyles = makeStyles(() => ({
    metaMarkerTooltipBoxText: {
        fontSize: '10px !important',
        lineHeight: '15px',
        color: '#fff',
        fontWeight: 400,
    },
    metaMarkerTooltipBox: {
        left: '1.25rem',
        top: 0,
        width: '18rem',
        height: '5rem',
        backgroundColor: 'rgba(0, 20, 255, 1)',
        zIndex: 9999,
    },
}));

interface CreateEvaModelProps {
    setDisableArrow?: React.Dispatch<React.SetStateAction<boolean>>;
    disableArrow?: boolean;
}

const Sidebar: React.FC<CreateEvaModelProps> = ({ setDisableArrow, disableArrow = false }) => {
    const { t } = useTranslation();
    const [data, setData] = useState<ThumbData[]>([]);

    const initialTrigger = 50;
    const protocolIndex = useSelector((state: RootState) => state.EvaInfo.protocolIndex);
    const isEvaluateMode = useSelector((state: RootState) => state.EvaInfo.isEvaluateMode);
    const isMetricsMode = useSelector((state: RootState) => state.EvaInfo.isMetricsMode);
    const nuancesWithThreshold = useSelector((state: RootState) => state.EvaInfo.nuancesWithThreshold);
    const isProtocolEditMode = useSelector((state: RootState) => state.EvaInfo.isProtocolEditMode);
    const evaId = useSelector((state: RootState) => state.EvaInfo.evaId);
    const [showProtocolNameInput, setShowProtocolNameInput] = useState(false);
    const protocolName = useSelector((state: RootState) => state.EvaInfo.protocolName);
    const copyOfProtocolName = useSelector((state: RootState) => state.EvaInfo.copyOfProtocolName);

    const triggerNumber = useSelector((state: RootState) => state.EvaInfo.triggerNumber);
    const copyTriggerNumber = useSelector((state: RootState) => state.EvaInfo.copyTriggerNumber);
    const protocolType = useSelector((state: RootState) => state.EvaInfo.protocolType);
    const copyOfProtocolType = useSelector((state: RootState) => state.EvaInfo.copyOfProtocolType);
    const nuancesValidate = useSelector((state: RootState) => state.EvaInfo.nuancesValidate);
    const triggerIcon = useSelector((state: RootState) => state.EvaInfo.triggerIcon);
    const copyOfTriggerIcon = useSelector((state: RootState) => state.EvaInfo.copyOfTriggerIcon);

    const checkNuancesLeg = useSelector((state: RootState) => state.EvaInfo.checkNuancesLeg);
    const isEnableProtocolType = useSelector((state: RootState) => state.EvaInfo.isEnableProtocolType);
    const isCopyEnableProtocolType = useSelector((state: RootState) => state.EvaInfo.isCopyEnableProtocolType);
    const metaMarker = useSelector((state: RootState) => state.EvaInfo.evaProtocolsData);
    const protocolThresholdValues = useSelector((state: RootState) => state.EvaInfo.protocolThresholdValues);

    const isMetricLoading = useSelector((state: RootState) => state.EvaInfo.metricProtocolLoading);
    const isChangeProtocolData = useSelector((state: RootState) => state.EvaInfo.isChangeProtocolData);
    const updateDate = useSelector((state: RootState) => state.EvaInfo.updateDate);
    const changeNuancesScreen = useSelector((state: RootState) => state.EvaInfo.changeNuancesScreen);
    const apiNuances = useSelector((state: RootState) => state.EvaInfo.apiNuances);
    const addProtocolLoading = useSelector((state: RootState) => state.EvaInfo.addProtocolLoading);
    const uploadFileLoading = useSelector((state: RootState) => state.EvaInfo.uploadFileLoading);
    const isFetchError = useSelector((state: RootState) => state.EvaInfo.isFetchError);

    const protocolColor = metaMarker?.find((item: any, index: number | null) => index === protocolIndex)?.color;
    const createdAt = metaMarker?.find((item: any, index: number | null) => index === protocolIndex)
        ?.protocolIdentification?.created_at;

    const evaService = new EvaService();
    const [showInput, setShowInput] = useState<boolean>(false);
    const [nuanceInputValue, setNuanceInputValue] = useState<string>('');
    const [editTitlePosition, setEditTitlePosition] = useState<number | null>(null);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const titlesRef = useRef<HTMLDivElement>(null);
    const selectedVal = 0;
    const [dragSelectVal, setDragSelectVal] = useState<boolean>(false);
    const [showNuancesMsg, setShowNuancesMsg] = useState<boolean>(true);

    const [copyMetaMarkerValue, setCopyMetaMarkerValue] = useState<string>('');
    const [copyTriggerIcon, setCopyTriggerIcon] = useState<string>(triggerIcon);
    const [blobcode, setBlobcode] = useState<Blob | null>(null);

    // select dropdown =========
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [protocolNuances, setProtocolNuances] = useState<Nuances[]>([]);
    const [optionList, setOptionList] = useState<ProtocolOptionType[]>(OptionData);
    const [currentOption, setCurrentOption] = useState<ProtocolOptionType>();
    const [checkChangeData, setCheckChangeData] = useState(false);
    const customSelectRef = useRef<HTMLDivElement>(null);
    const [isDuplicateNuance, setIsDuplicateNuance] = useState<boolean>(false);

    const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
    const [currentThreshold, setCurrentThreshold] = useState<number | null>(null);

    const metaMarkerLength = metaMarker?.length;
    const [dotHoverIndex, setDotHoverIndex] = useState<null | number>(null);

    const [fileInfo, setFileInfo] = useState({
        fileName: '',
        fileSize: '',
        fileType: '',
    });

    const dispatch = useDispatch();

    // swap data convert
    const [nuancesData, setNuancesData] = useState<ThumbData[]>([]);
    const [nuancesDataValues, setNuancesDataValues] = useState<number[]>([]);
    const [InputFocus, setInputFocus] = useState(false);
    const [emptyNuances, setEmptyNuances] = useState(false);
    const [isTitleEmpty, setIsTitleEmpty] = useState(false);

    // copy orignal nuance
    const [copyNuancesData, setCopyNuancesData] = useState<ThumbData[]>([]);
    const [copyNuancesValues, setCopyNuancesValues] = useState<number[]>([]);
    const [copyApiNuances, setCopyApiNuances] = useState<Nuances[]>([]);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const checkNuancesData = copyApiNuances?.length === apiNuances?.length ? true : false;
    // new design
    const protocolNuancesData = convertToThumbData(nuancesWithThreshold);
    const TrackHeight = 368;

    const sliderHeight = TrackHeight - 5;

    const thresholdValues: number[] = protocolNuancesData?.map((item) => item.value);
    const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
    const acceptedFileTypes = [
        'application/msword', // .doc -
        'application/pdf', // .pdf -
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx -
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'text/xml', // .xml
        'text/csv', // .csv
        'text/plain', // .txt
        'application/json', // .json
        'text/html', // .html, .htm
        'application/xhtml+xml', // .xhtml
    ];

    // Helper function to check if MIME type contains '+xml'
    const isXmlFile = (fileType: string) => fileType.includes('+xml');

    let FileIcon;
    switch (fileInfo.fileType) {
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            FileIcon = DocFile;
            break;
        case 'application/pdf':
            FileIcon = PDFIcon;
            break;
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            FileIcon = XlsFile;
            break;
        case 'text/xml':
            FileIcon = XmlFile;
            break;
        case 'text/csv':
            FileIcon = CsvFile;
            break;
        case 'text/plain':
            FileIcon = TxtFile;
            break;
        case 'application/json':
            FileIcon = JsonFile;
            break;
        case 'text/html':
        case 'application/xhtml+xml':
            FileIcon = HtmlFile;
            break;
        default:
            FileIcon = OtherFile;
            break;
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (customSelectRef?.current && !customSelectRef?.current?.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (data || protocolThresholdValues) {
            const convertedData: Nuances[] = data?.flatMap((item) =>
                item?.titles?.map((title) => ({
                    nuance: title,
                    threshold: item.value,
                })),
            );
            setProtocolNuances(convertedData);
            dispatch(setCurrentProtocolNuances(convertedData));
            const filteredData = convertedData?.filter((item) => item?.nuance.trim() !== '');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, protocolThresholdValues, isEvaluateMode]);

    const isChangeProcolNuancesThreshold = deepCompareData(data, protocolNuancesData);

    const handleIconClick = (iconValue: string) => {
        dispatch(setTriggerIcon(iconValue));
    };

    useEffect(() => {
        if (isProtocolEditMode) {
            if (
                copyOfProtocolName !== protocolName ||
                copyTriggerNumber !== triggerNumber ||
                copyOfTriggerIcon !== triggerIcon ||
                copyOfProtocolType !== protocolType ||
                isCopyEnableProtocolType !== isEnableProtocolType ||
                !isChangeProcolNuancesThreshold ||
                apiNuances.length > 0
            ) {
                dispatch(setIsChangeProtocolData(true));
                // setClickOutSideSideBar(true);
            } else {
                // setClickOutSideSideBar(false);
                dispatch(setIsChangeProtocolData(false));
            }
        }
        for (const item of data) {
            if (item?.titles?.includes('')) {
                return setEmptyNuances(true);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isChangeProcolNuancesThreshold,
        copyOfTriggerIcon,
        protocolIndex,
        protocolName,
        triggerNumber,
        triggerIcon,
        protocolType,
        data,
        isProtocolEditMode,
        isEnableProtocolType,
        copyOfProtocolName,
        copyOfProtocolType,
        apiNuances,
    ]);

    //============== end code , open conform modal when i close eva sidebar ==================

    const findDuplicateNuances = () => {
        const allTitles = data.flatMap((item) => item.titles);
        const titleCounts: Record<string, number> = {};
        allTitles.forEach((title) => {
            titleCounts[title] = (titleCounts[title] || 0) + 1;
        });
        const hasDuplicate = Object.values(titleCounts).some((count) => count > 1);
        setIsDuplicateNuance(hasDuplicate);
    };

    useEffect(() => {
        if (selectedItemIndex !== null) {
            setShowInput(true);
            setDotHoverIndex(null);
            setShowNuancesMsg(false);
        } else {
            setShowInput(false);
            setEditTitlePosition(null);
        }

        if (selectedItemIndex !== null && editTitlePosition !== null) {
            // Filter out items where all titles are empty or whitespace
            const filteredData = data.filter((item) => item.titles.some((title) => title.trim() !== ''));
            // Check if the specific title is empty in the filtered data
            const isEmptyTitle = filteredData[selectedItemIndex]?.titles[editTitlePosition]?.trim() === '';
            // Set the state for isTitleEmpty based on the result
            setIsTitleEmpty(isEmptyTitle);
        }
        findDuplicateNuances();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemIndex, editTitlePosition, data, protocolThresholdValues]);

    useEffect(() => {
        if (selectedItemIndex == null) {
            if (emptyNuances) {
                checkEmptyNuances();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemIndex, emptyNuances]);

    // slider left side ==========

    const handleChangeMetaMarker = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setProtocolName(e.target.value));
        dispatch(setMetaMarkerValidation(false));

        if (isEnableProtocolType) {
            setCheckChangeData(true);
        }
    };

    useEffect(() => {
        if (nuancesWithThreshold) {
            dispatch(setProtocolThresholdValues(thresholdValues));
            setData(protocolNuancesData);
            setCopyNuancesData(protocolNuancesData);
            setCopyNuancesValues(thresholdValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nuancesWithThreshold]);

    // convert data to api response formate

    useEffect(() => {
        if (!isProtocolEditMode || protocolIndex === null) {
            dispatch(setNuancesWithThreshold([]));
            dispatch(setProtocolName(''));
            dispatch(setCopyOfProtocolName(''));

            setNuanceInputValue('');
            dispatch(setTriggerNumber(initialTrigger));
            dispatch(setCopyTriggerNumber(initialTrigger));
            setSelectedItemIndex(null);
            setEditTitlePosition(null);
            dispatch(setMetaMarkerValidation(false));
            dispatch(setNuancesValidate(false));

            setShowInput(false);
            setShowNuancesMsg(true);
            dispatch(setTriggerIcon('>'));
            dispatch(setIsChangeProtocolData(false));

            setCheckChangeData(false);
            dispatch(setEnableProtocolType(true));
            dispatch(setCopyEnableProtocolType(true));

            dispatch(setProtocolType('Custom'));
            dispatch(setCopyOfProtocolType('Custom'));

            setData([]);

            dispatch(setProtocolThresholdValues([]));
            setCopyNuancesValues([]);
            setCopyNuancesData([]);
            setNuancesDataValues([]);
            setNuancesData([]);
            setIsDuplicateNuance(false);
            dispatch(setConfirmationScreen(false));
            dispatch(dispatch(setCheCkNuancesLeg(false)));
            setEmptyNuances(false);
            setIsTitleEmpty(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProtocolEditMode, protocolIndex]);

    // submit data
    const handleSubmitData = (event: React.MouseEvent<HTMLElement>) => {
        for (const item of data) {
            if (item.titles.includes('')) {
                return dispatch(dispatch(setCheCkNuancesLeg(true)));
            }
        }

        // Check for duplicate protocol
        const isDuplicate = metaMarker.some(
            (protocol: { metaMarker: string }, index: number | null) =>
                protocol.metaMarker.toLowerCase() === protocolName.toLowerCase() && index !== protocolIndex,
        );

        if (isDuplicate) {
            dispatch(setProtocolErrorMessage(t('protocol_name_exists')));
            return;
        }

        dispatch(setOrbitLoader(true));
        dispatch(setAddProtocolLoading(true));
        dispatch(setApiNuances([]));

        setTimeout(() => {
            // Create an EVA Meta Marker with the event details
            dispatch(setSaveProtocolEvent(event));
            // Set button loading state to false after the operations
            setCopyMetaMarkerValue(protocolName);
            dispatch(setCopyTriggerNumber(triggerNumber));
            setCopyTriggerIcon(triggerIcon);
            dispatch(setProtocolEditMode(true));
            dispatch(setProtocolErrorMessage(null));
            // Check if the input is shown
            // If the input value is empty, set the check nuances flag to true
        }, 1000);
    };

    // remove Nuance one by one
    const handleRemoveNuance = (
        index: number,
        titleIndex: number,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        // Prevent the event from propagating further
        event.stopPropagation();
        // Update the data state
        setData((prevData) => {
            const newData = [...prevData];
            // Remove the title at the specified titleIndex
            newData[index]?.titles?.splice(titleIndex, 1);
            // If the titles array is empty after removal, remove the entire entry
            if (newData[index]?.titles.length === 0) {
                newData.splice(index, 1);
                // Update values state accordingly

                const newValues = [...protocolThresholdValues];
                newValues.splice(index, 1);
                dispatch(setProtocolThresholdValues(newValues));

                // Hide input and show nuances message
                setShowInput(false);
                setShowNuancesMsg(true);
                setNuanceInputValue('');
            }
            return newData;
        });

        // Adjust the edit title position and input value if titles remain
        setData((prevData) => {
            const newData = [...prevData];
            if (titleIndex > 0) {
                setEditTitlePosition(titleIndex - 1);
                setNuanceInputValue(newData[index]?.titles[titleIndex - 1] || '');
            } else if (titleIndex === 0 && newData[index]?.titles.length > 0) {
                setEditTitlePosition(0);
                setNuanceInputValue(newData[index]?.titles[0] || '');
            }
            return newData;
        });

        // Check if the selected item has no titles and reset the selected index if necessary
        if (data?.find((obj, idx) => idx === selectedItemIndex)?.titles.length === 0) {
            setSelectedItemIndex(null);
        }
    };

    // current selected Nuance and heigh light
    const handleSpanClick = (index: number) => {
        if (selectedItemIndex !== index) {
            checkEmptyNuances();
        }
        // Check if dragSelectVal is false before proceeding
        if (!dragSelectVal) {
            // Toggle the selected item index between the current index and null
            setSelectedItemIndex((prevIndex) => (prevIndex === index ? null : index));
            // Reset the edit title position to null
            setEditTitlePosition(null);
            // Toggle the visibility of the input field
            setShowInput(!showInput);
            // Toggle the visibility of the nuances message
            setShowNuancesMsg(!showNuancesMsg);
            // Get the title data for the specified index
            const getTitle = data[index];
            // If the clicked item is not the currently selected item, set the input value and edit position
            if (selectedItemIndex !== index) {
                setNuanceInputValue(getTitle?.titles[0] || '');
                setEditTitlePosition(0);
            } else {
                // Clear the input value if the item is deselected
                setNuanceInputValue('');
            }
        }
    };

    useEffect(() => {
        // Check if selectedItemIndex is not null or undefined
        if (selectedItemIndex) {
            // Find the data item corresponding to selectedItemIndex
            const singleData = data?.find((item, i) => i === selectedItemIndex);
            // If the data item exists, update the current threshold
            if (singleData) {
                setCurrentThreshold(singleData?.value);
            }
        } else {
            // Special case when selectedItemIndex is exactly 0
            if (selectedItemIndex === 0) {
                const singleData = data[0];
                // Update the current threshold with the value of the first item
                setCurrentThreshold(singleData?.value);
            }
        }

        if (selectedItemIndex == null) {
            setIsTitleEmpty(false);
        }

        if (showInput) {
            if (nuanceInputValue.length > 0) {
                dispatch(dispatch(setCheCkNuancesLeg(false)));
            } else {
                setIsTitleEmpty(true);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemIndex, nuanceInputValue, showInput]);

    const selectTitle = (
        nuance: string,
        index: number,
        nuanceIndex: number,
        event: React.MouseEvent<HTMLDivElement>,
    ) => {
        event.stopPropagation();
        setEditTitlePosition(index);
        setNuanceInputValue(nuance);
    };

    const nuanceHandleChange = (nuance: string) => {
        setCheckChangeData(true);
        setNuanceInputValue(nuance);
        if (editTitlePosition !== null && selectedItemIndex !== null) {
            setData((prevData) => {
                const newData = [...prevData];
                const selectedItem = newData[selectedItemIndex];

                // If nuance is not empty, update the title; if empty, set the title to an empty string
                const updatedTitles = selectedItem.titles.map((title, index) =>
                    index === editTitlePosition ? nuance : title,
                );

                newData[selectedItemIndex] = { ...selectedItem, titles: updatedTitles };
                return newData;
            });
        }
        // Additional logic for updating if the titles array is empty and selectedItemIndex is not null
        if (selectedItemIndex !== null && nuance.trim() !== '') {
            setData((prevData) => {
                return prevData.map((item, index) => {
                    if (index === selectedItemIndex) {
                        const updatedTitles = item.titles.length === 0 ? [nuance] : [...item.titles];
                        return { ...item, titles: updatedTitles };
                    }
                    return item;
                });
            });
            setEditTitlePosition(editTitlePosition);
        }
        dispatch(setNuancesValidate(false));
    };

    // Check if the data array has at least 2 items or if every item in the data array has at least 2 titles
    const allNuanceLengthTwo = data?.length >= 2 || data?.every((item) => item?.titles?.length >= 2);

    // add validation Nuance length is GreaterThan 15 hide a plus button
    function checkTitleArrayLength(data: { value: number; titles: string[] }[]): boolean {
        let totalLength = 0;
        // Iterate over each item in the data array
        for (let i = 0; i < data?.length; i++) {
            // Add the length of the titles array of the current item to totalLength
            totalLength += data[i].titles.length;
        }
        // If totalLength is 15 or more, return false; otherwise, return true
        if (totalLength >= 15) {
            return false;
        } else {
            return true;
        }
    }
    useEffect(() => {
        if (editTitlePosition !== null) {
            if (editTitlePosition > 1) {
                scrollToBottom();
            }
        }

        // Call the scrollToBottom function whenever the data array changes
    }, [data, dispatch, editTitlePosition]);

    const scrollToBottom = () => {
        // Check if titlesRef.current is defined
        if (titlesRef.current) {
            // Set the scrollTop of the element to its scrollHeight to scroll to the bottom
            titlesRef.current.scrollTop = titlesRef.current.scrollHeight;
        }
    };

    const addNuance = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedIndex: number) => {
        // Prevent the event from propagating further
        event.stopPropagation();
        // Clear the detail input value
        setNuanceInputValue('');
        setShowInput(true);
        setShowNuancesMsg(false);
        setTimeout(() => {
            // Check if a valid selectedIndex is provided
            if (selectedIndex !== null) {
                // Update the data with the new title
                setData((prevData) => {
                    const updatedData = prevData.map((item, i) =>
                        // If the index matches the selectedIndex, update its titles array
                        i === selectedIndex
                            ? {
                                  ...item,
                                  titles: [
                                      ...item.titles,
                                      // Add an empty string if the nuanceInputValue is empty, otherwise add the current value
                                      nuanceInputValue?.length > 0 ? '' : nuanceInputValue,
                                  ],
                              }
                            : item,
                    );
                    // Set the edit title position to the index of the last added title
                    const lastAddedIndex = updatedData[selectedIndex].titles.length - 1;
                    setEditTitlePosition(lastAddedIndex);
                    return updatedData;
                });
            }
        }, 100);
    };

    const handleAddThumb = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        // Check if 0 is already present in the values array
        const isZeroPresent = protocolThresholdValues.includes(0);
        // Find the index of 0 in the values array
        const zeroIndex = protocolThresholdValues.indexOf(0);
        // Set the selected item index to the index of 0 in the values array
        setSelectedItemIndex(zeroIndex);
        // If 0 is not present, proceed to add the new item
        if (!isZeroPresent && selectedVal !== null) {
            const newValues = [...protocolThresholdValues, selectedVal];
            // setValues(newValues);
            dispatch(setProtocolThresholdValues(newValues));

            // Create a new thumb data object with selectedVal as its value and an empty title array
            const newThumbData: ThumbData = { value: selectedVal, titles: [''] };
            // Create a new array with newThumbData added to the existing data
            const newData = [...data, newThumbData];
            // Set the data state to the new array
            setData(newData);
            // Calculate the index of the newly added value
            const index = newValues.length - 1;
            // Set the selected item index to the index of the newly added value
            setSelectedItemIndex(index);
            // Set the edit title position to 0
            setEditTitlePosition(0);
            // Hide the nuances message
            setShowNuancesMsg(false);
        } else if (isZeroPresent && selectedVal !== null) {
            // Call the addNuance function if 0 is already present
            addNuance(event, zeroIndex);
        }
        // Hide the nuances message
        setShowNuancesMsg(false);
        // Show the input field
        setShowInput(true);
    };

    const checkNuances = () => {
        if (nuanceInputValue.trim() === '') {
            dispatch(setCheCkNuancesLeg(true));
            // If detail input value is empty, set the nuances check flag to true
        } else {
            setShowNuancesMsg(true);
            // Hide the input field
            setShowInput(false);
        }
        // Check if detail input value's length is 0
        if (nuanceInputValue?.length === 0) {
            // If detail input value's length is 0 and there's data
            if (data) {
                // Filter items with non-empty titles
                const itemsWithNonEmptyTitles = data?.filter((item) => item.titles && item.titles.length > 0);
                // Check if the number of items with non-empty titles is less than 2
                if (itemsWithNonEmptyTitles.length < 2) {
                    // If less than 2, set the nuances check flag to true
                    dispatch(setCheCkNuancesLeg(true));
                }
            }
        } else {
            dispatch(setCheCkNuancesLeg(false));
            // Reset selected item index
            setSelectedItemIndex(null);
            // Clear detail input value
            setNuanceInputValue('');
            // Show the nuances message
            setShowNuancesMsg(true);
        }
    };

    // left side bar
    const handleChange = (triggerValue: number) => {
        dispatch(setTriggerNumber(triggerValue));
    };

    const renderThumb = (props: any) => {
        return (
            // Container for the thumb
            <div {...props} className='main_thumb'>
                {/* Container for the scroll area */}
                <div className='scrollthumb'>
                    {/* Container for the flag icon */}
                    <div className='scroll_flag'>
                        {/* Flag icon component */}
                        <FlagIcon />
                        {/* Conditional rendering of arrow icon */}
                        {triggerIcon === '<' ? (
                            <div
                                // Apply CSS class based on iconState for arrow rotation
                                className={`${triggerIcon === '<' ? 'roted_arrow' : ''} arrow`}
                                // Handle click event to change iconState
                                onClick={() => handleIconClick('>')}
                            >
                                {/* Right arrow icon */}
                                <FaAngleRight />
                            </div>
                        ) : (
                            <div
                                // Container for arrow icon
                                className='arrow'
                                // Handle click event to change iconState
                                onClick={() => handleIconClick('<')}
                            >
                                {/* Left arrow icon */}
                                <FaAngleRight />
                            </div>
                        )}
                    </div>
                    {/* Container for scroll information */}
                    <div className='scroll_info'>
                        <p>
                            <div dangerouslySetInnerHTML={{ __html: t('interaction_level') }} />
                        </p>
                    </div>
                </div>
                {/* Container for horizontal line */}
                <div className='horizontal-line'>
                    {/* Horizontal line component */}
                    <HorizontalLine />
                </div>
                {/* Display trigger number */}
                <div className='scroll_count'>{triggerNumber}</div>
            </div>
        );
    };

    const checkEmptyNuances = () => {
        const updatedData = data
            .filter((item) => {
                const filteredTitles = item?.titles?.filter((title) => title.trim() !== '');
                return filteredTitles.length > 0;
            })
            .map((item) => ({
                ...item,
                titles: item?.titles?.filter((title) => title.trim() !== ''), // Ensure titles are cleaned
            }));
        const updatedValues = updatedData.map((item) => item.value);
        setData(updatedData);
        dispatch(setProtocolThresholdValues(updatedValues));
    };

    const handleChangeThreshold = (val: number) => {
        // Check for empty nuances
        if (emptyNuances) {
            checkEmptyNuances();
        }

        const newValue = val;
        // Ensure newValue is a valid number between 0 and 100 and not the current threshold
        if (!isNaN(newValue) && newValue >= 0 && newValue <= 100 && newValue !== currentThreshold) {
            setCurrentThreshold(newValue);

            // Check if selectedItemIndex is valid
            if (
                selectedItemIndex !== null &&
                selectedItemIndex >= 0 &&
                selectedItemIndex < protocolThresholdValues.length
            ) {
                // Check if the newValue is already present in `values` (excluding the selected item index)
                const isValueAlreadyPresent = protocolThresholdValues.some(
                    (value, index) => index !== selectedItemIndex && value === newValue,
                );

                if (!isValueAlreadyPresent) {
                    // Handle title editing if editTitlePosition is provided
                    if (
                        editTitlePosition !== null &&
                        editTitlePosition >= 0 &&
                        data[selectedItemIndex]?.titles?.length > 1
                    ) {
                        const newValues = [...protocolThresholdValues];
                        const newData = [...data];

                        // Insert newValue at selectedItemIndex in values
                        newValues.splice(selectedItemIndex, 0, newValue);

                        // Clone the selected data item
                        const copiedThumb = { ...data[selectedItemIndex] };
                        const uniqueTitles = Array.from(new Set(data[selectedItemIndex].titles)); // Ensure unique titles

                        // Insert a copy of the selected item at the next index
                        newData.splice(selectedItemIndex + 1, 0, copiedThumb);
                        // Remove the title at editTitlePosition from the original item and update the value
                        newData[selectedItemIndex].titles.splice(editTitlePosition, 1);
                        newData[selectedItemIndex].value = newValue;
                        // Update the original item in newData with the title at the editTitlePosition
                        const updatedOriginalThumb = {
                            ...data[selectedItemIndex],
                            titles: [uniqueTitles[editTitlePosition]],
                        };
                        newData[selectedItemIndex] = updatedOriginalThumb;

                        // Update state
                        dispatch(setProtocolThresholdValues(newValues));

                        setData(newData);
                    } else {
                        // If no editTitlePosition or only one title exists
                        const updatedValues = [...protocolThresholdValues];
                        updatedValues[selectedItemIndex] = newValue;
                        dispatch(setProtocolThresholdValues(updatedValues));

                        const updatedData = [...data];
                        updatedData[selectedItemIndex] = { ...updatedData[selectedItemIndex], value: newValue };
                        setData(updatedData);
                    }
                    // Reset editTitlePosition after updates
                    setEditTitlePosition(0);
                } else {
                    // If the value is already present, remove currentThreshold from `values` and merge titles
                    const updatedValues = [...protocolThresholdValues];
                    const currentThresholdIndex = updatedValues.indexOf(Number(currentThreshold));
                    const updatedData = data.filter((item) => item.value !== currentThreshold);

                    if (currentThresholdIndex >= 0) {
                        updatedValues.splice(currentThresholdIndex, 1); // Remove the current threshold
                    }

                    const tempId = updatedValues.indexOf(newValue);
                    const newData = updatedData.map((item) => {
                        return item.value === updatedValues[tempId]
                            ? {
                                  ...item,
                                  titles: Array.from(new Set([...data[selectedItemIndex].titles, ...item.titles])), // Merge titles
                              }
                            : item;
                    });

                    setEditTitlePosition(0);

                    // Update state
                    dispatch(setProtocolThresholdValues(updatedValues));

                    setData(newData);
                    setSelectedItemIndex(updatedValues.indexOf(newValue));
                }
            }
        }

        // Set checkChangeData to true
        setCheckChangeData(true);
    };

    useEffect(() => {
        if (InputFocus && checkChangeData && emptyNuances && currentThreshold) {
            checkEmptyNuances();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentThreshold]);
    const HandelChange = (name: string) => {
        if (!isEnableProtocolType) {
            dispatch(setProtocolType(name));
        }
        setIsOpen(false);
    };
    useEffect(() => {
        const current = optionList.find((option) => option.name == protocolType);
        if (current !== undefined) {
            setCurrentOption(current);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [protocolType, protocolType]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const updateMetaData = async () => {
        if (isEnableProtocolType) {
            setIsLoading(true);
            const protocolTypes = await evaService.getProtocolTypes({
                metaMarker: protocolName,
                nuances: protocolNuances,
            });
            setIsLoading(false);
            setCheckChangeData(false);

            if (protocolTypes) {
                dispatch(setProtocolType(typeof protocolTypes.name === 'object' ? 'Custom' : protocolTypes.name));
                const exists = optionList?.some((option) => option.name === protocolTypes.name);
                if (!exists) {
                    setOptionList([
                        ...optionList,
                        {
                            name: protocolTypes.name,
                            icon: '',
                            txtColor: '',
                            linearBg: '',
                            description: '',
                            fillIcon: '',
                            lineColor: '',
                            bgColor: '',
                            transparentIcon: '',
                        },
                    ]);
                }
            }
        }
    };

    useEffect(() => {
        if (isEnableProtocolType !== isCopyEnableProtocolType && data.length > 0) {
            updateMetaData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnableProtocolType]);

    useEffect(() => {
        //metrics create new protocol
        if (isEnableProtocolType && isMetricsMode) {
            updateMetaData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnableProtocolType, isMetricsMode]);

    const handelProtocolType = () => {
        if (isEnableProtocolType) {
            if (checkChangeData) {
                updateMetaData();
            }
        }
    };
    const {
        isLoading: protocolNuancesLoading,
        isFetching,
        refetch,
        isError,
    } = useQuery(['postNuancesData', blobcode], async () => {
        try {
            if (blobcode && evaId) {
                const formData = new FormData();
                formData.append('file', blobcode);
                dispatch(setUploadFileLoading(true));
                const data = await evaService.postNuancesData(formData, evaId);
                dispatch(setUploadFileLoading(false));
                if (data.length > 0) {
                    const formattedData = {
                        ...data[0],
                        nuances: data[0].nuances?.map((nuance: { nuance: string }) => ({
                            ...nuance,
                            nuance: formatCamelCase(nuance.nuance),
                        })),
                    };
                    // Update state with formatted nuances
                    dispatch(setApiNuances(formattedData?.nuances));
                    setCopyApiNuances(formattedData?.nuances);
                }
            }
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
                setErrorMsg(e.message);
            }
        }
    });

    // upload file
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const blobData = new Blob([file], { type: file.type });

            // Validate file size
            if (file.size > maxFileSize) {
                dispatch(setFileError('File size does not exceed 10MB'));
                setFileInfo({ fileName: '', fileSize: '', fileType: '' });
                return;
            }

            // Validate file type
            const isAcceptedFileType = acceptedFileTypes.includes(file.type) || isXmlFile(file.type);
            if (!isAcceptedFileType) {
                dispatch(setFileError(t('invalid_file_type')));
                setFileInfo({ fileName: '', fileSize: '', fileType: '' });
                return;
            }
            // If validation passes
            const fileSizeMB = (file.size / 1024 / 1024).toFixed(2) + ' MB';
            const fileSizeKB = (file.size / 1024).toFixed(2) + ' KB';
            dispatch(setFileError(null));
            setFileInfo({
                fileName: file.name,
                fileSize: fileSizeKB || fileSizeMB,
                fileType: file.type,
            });
            setBlobcode(blobData);
            dispatch(setChangeNuancesScreen(true));

            refetch();
        }
        // Reset the input value to allow the same file to be selected again
        e.target.value = '';
    };
    const removeFile = () => {
        setFileInfo({ fileName: '', fileSize: '', fileType: '' });
        setBlobcode(null);
    };

    const confirm = () => {
        dispatch(setChangeNuancesScreen(false));

        setFileInfo({ fileName: '', fileSize: '', fileType: '' });
    };

    const swapData = () => {
        if (nuancesData && nuancesDataValues) {
            setData(nuancesData);
            dispatch(setProtocolThresholdValues(nuancesDataValues));
        }
    };
    const resetData = () => {
        if (!checkNuancesData) {
            refetch();
        }
    };

    const resetOriginalProtocol = () => {
        setData(protocolNuancesData);
        dispatch(setProtocolThresholdValues(thresholdValues));

        setNuanceInputValue('');
        setShowInput(false);
    };

    const cancel = () => {
        if (copyNuancesData && copyNuancesValues) {
            setData(copyNuancesData);
            dispatch(setProtocolThresholdValues(copyNuancesValues));

            dispatch(setChangeNuancesScreen(false));

            setCopyApiNuances([]);
            dispatch(setApiNuances([]));

            setFileInfo({ fileName: '', fileSize: '', fileType: '' });
            setBlobcode(null);
        }
    };

    // protocol slide left and  right arrow

    const handleLeftClick = () => {
        const newIndex = protocolIndex === null ? metaMarkerLength - 1 : protocolIndex > 0 ? protocolIndex - 1 : 0;
        dispatch(setProtocolIndex(newIndex));

        setSelectedItemIndex(null);
        dispatch(setCheCkNuancesLeg(false));
    };

    const handleRightClick = () => {
        const newIndex =
            protocolIndex === null ? 1 : protocolIndex < metaMarkerLength - 1 ? protocolIndex + 1 : protocolIndex;
        dispatch(setProtocolIndex(newIndex));

        setSelectedItemIndex(null);
        dispatch(setCheCkNuancesLeg(false));
    };

    useEffect(() => {
        if (!InputFocus && !disableArrow) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'ArrowLeft') {
                    handleLeftClick();
                } else if (event.key === 'ArrowRight') {
                    handleRightClick();
                }
            };

            window.addEventListener('keydown', handleKeyDown);

            // Cleanup the event listener on component unmount
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [protocolIndex, metaMarkerLength, InputFocus, disableArrow]);

    if (isError || isFetchError) {
        return <ErrorComponent />;
    }

    return (
        <>
            <Box className='h-full main_sidebar_box flex justify-start flex-col'>
                {isLoading && <div className='loading_overly' />}

                <div className={`protocol_detail  ${addProtocolLoading || uploadFileLoading ? 'disable_overly' : ''} `}>
                    <div className='protocol_heading flex justify-between items-center'>
                        <>
                            <div className='flex items-center left_input_box  gap-3'>
                                <div
                                    className='cursor-pointer'
                                    onClick={() => {
                                        if (isChangeProtocolData && isProtocolEditMode) {
                                            dispatch(setConfirmationScreen(true));
                                            dispatch(setSaveConfirmModal(true));
                                        } else {
                                            dispatch(setProtocolEditMode(false));
                                            dispatch(setFileError(null));
                                            dispatch(setProtocolErrorMessage(null));
                                        }
                                        dispatch(setMetaMarkerValidation(false));
                                    }}
                                >
                                    <LeftArrows />
                                </div>

                                <div className='flex gap-3  items-center w-full'>
                                    <div className='round flex justify-center items-center'>
                                        <div className='circle' style={{ backgroundColor: protocolColor }} />
                                    </div>

                                    <Box className='top_input_box'>
                                        {showProtocolNameInput ? (
                                            <Box>
                                                <div className='flex justify-between gap-2 items-center'>
                                                    <input
                                                        autoComplete='off'
                                                        id='metaMarker-title'
                                                        name='metaMarker'
                                                        onMouseLeave={handelProtocolType}
                                                        type='text'
                                                        autoFocus={true}
                                                        maxLength={40}
                                                        onFocus={() => setInputFocus(true)}
                                                        value={protocolName}
                                                        // placeholder={t('protocol_label', {
                                                        //     index:
                                                        //         (protocolIndex || protocolIndex === 0) &&
                                                        //         typeof protocolIndex === 'number'
                                                        //             ? protocolIndex + 1
                                                        //             : (metaMarker?.length || 0) + 1,
                                                        // })}
                                                        className='meta-input bg-transparent title_input text-center text-white focus:bg-none text-opacity-75 w-full pt-50 pb-15'
                                                        onBlur={() => {
                                                            setInputFocus(false);
                                                            setShowProtocolNameInput(false);
                                                        }}
                                                        onChange={handleChangeMetaMarker}
                                                    />
                                                    <div className='length_counter flex items-center'>
                                                        <span> {protocolName?.length} </span> /<span> 40 </span>
                                                    </div>
                                                </div>
                                            </Box>
                                        ) : (
                                            <div
                                                onBlur={() => {
                                                    setShowProtocolNameInput(false);
                                                }}
                                                onClick={() => setShowProtocolNameInput(true)}
                                                className='protocol_name flex gap-2 items-center'
                                            >
                                                <h5>
                                                    {protocolName ? protocolName : t('protocolDetail.createProtocol')}
                                                </h5>
                                                <EditBtn />
                                            </div>
                                        )}
                                    </Box>
                                </div>
                            </div>

                            <div className='flex justify-end gap-4'>
                                <div className='bottom_submit_btn'>
                                    <Button
                                        className='add_btn'
                                        onClick={() => {
                                            dispatch(setDeleteProtocol(true));
                                            dispatch(setApiNuances([]));
                                            dispatch(setChangeNuancesScreen(false));
                                        }}
                                    >
                                        <IconDelete />
                                    </Button>
                                </div>

                                {allNuanceLengthTwo && data?.length !== 0 && (
                                    <div className={` ${isDuplicateNuance ? 'disable_btn' : ''} bottom_submit_btn`}>
                                        {addProtocolLoading ? (
                                            <button className='loading_btn' type='button' disabled>
                                                <span className='animate-spin '> </span>
                                            </button>
                                        ) : (
                                            <Button
                                                className={`${isChangeProtocolData ? '' : 'disable_btn '} add_btn`}
                                                onClick={(e) => (isChangeProtocolData ? handleSubmitData(e) : noop())}
                                            >
                                                <IconRight />
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </>
                    </div>

                    <div className='top_date_box flex items-center justify-between '>
                        <>
                            <div className='flex items-center gap-4'>
                                <Tooltip id='evaluateMode' offset={5} place='top' className='tooltip-box show_arrow'>
                                    <p> {t('evaluate')} </p>
                                </Tooltip>
                                <Tooltip id='metricsMode' offset={5} place='top' className='tooltip-box show_arrow'>
                                    <p> {t('metrics')} </p>
                                </Tooltip>
                                <Tooltip id='upload-file' offset={5} place='top' className='tooltip-box show_arrow'>
                                    <p> {t('map_protocol')} </p>
                                </Tooltip>

                                <button
                                    data-tooltip-id='evaluateMode'
                                    className='rsp-icon eay_btn relative'
                                    onClick={() => {
                                        {
                                            dispatch(setEvaluateMode(!isEvaluateMode));
                                            dispatch(setMetricsMode(false));
                                            if (setDisableArrow) {
                                                setDisableArrow(false);
                                            }
                                        }
                                    }}
                                >
                                    {isEvaluateMode ? (
                                        <div className='eva-test-eye-icon-active'>
                                            <CloseEyeWhite width={15} height={15} fill='rgba(96, 244, 200, 0.75)' />
                                        </div>
                                    ) : (
                                        <CloseEye width={15} height={15} fill='rgba(111, 191, 225, 0.75)' />
                                    )}
                                </button>
                                <button
                                    data-tooltip-id='metricsMode'
                                    className={clsx('rsp-icon relative eay_btn', isMetricsMode && 'rsp-icon-selected')}
                                    onClick={() => {
                                        {
                                            dispatch(setMetricsMode(!isMetricsMode));
                                            dispatch(setEvaluateMode(false));
                                        }
                                    }}
                                >
                                    {/* {showNotification && <div className='metric-notification' />} */}
                                    <MetricsIcon className='w-3 h-3' fill={'rgba(111, 191, 225, 0.75)'} />
                                </button>
                                <span className='right_border' />
                                <div
                                    className='file_upload_box flex items-center justify-between'
                                    data-tooltip-id='upload-file'
                                >
                                    <div className='upload_btn_box flex items-center gap-2 relative'>
                                        <button
                                            className={`${fileInfo?.fileName ? 'active_file' : ''} ${
                                                protocolNuancesLoading || isFetching ? 'file_loading_btn' : ''
                                            }  upload_btn  sidebar_common_btn flex items-center  justify-center `}
                                        >
                                            <input
                                                type='file'
                                                name=''
                                                onChange={handleFileChange}
                                                accept='.doc,.docx,.pdf,.xls,.xlsx,.xml,.csv,.txt,.json,.html,.htm,.xhtml'
                                                id=''
                                            />
                                            {protocolNuancesLoading || isFetching ? (
                                                <div className='file_loading' />
                                            ) : (
                                                <>
                                                    {fileInfo?.fileName ? (
                                                        <ActiveFileUploadIcon />
                                                    ) : (
                                                        <>
                                                            <FileUploadIcon className='before_icon' />
                                                            <FileUploadIconHover className='after_icon' />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </button>

                                        {fileInfo?.fileName && (
                                            <div className='file_info'>
                                                <p>
                                                    <FileIcon /> <span className='ml-3'> {fileInfo?.fileName} </span>
                                                </p>
                                                <p>
                                                    {fileInfo.fileSize}
                                                    <button
                                                        onClick={removeFile}
                                                        className='remove_file cursor-pointer ml-4'
                                                    >
                                                        <CloseIcon />
                                                    </button>
                                                </p>
                                            </div>
                                        )}

                                        {/* {fileError && <p className='error_msg'>{fileError}</p>} */}
                                    </div>
                                </div>
                            </div>
                            <span>
                                {t('creation_date', {
                                    date: moment(createdAt).format('DD/MM/YY'),
                                })}
                            </span>
                        </>
                    </div>

                    <div className='main_container'>
                        <div className='flex items-center select_type_box'>
                            <div ref={customSelectRef}>
                                <div className='custom_select relative'>
                                    <div
                                        className={`${
                                            isOpen ? 'active' : ''
                                        } select_label flex items-center justify-between cursor-pointer`}
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        {isLoading ? (
                                            <div className='select_loader' />
                                        ) : (
                                            <>
                                                <p className='flex gap-2 items-center '>
                                                    <img
                                                        src={currentOption?.transparentIcon}
                                                        alt={currentOption?.transparentIcon}
                                                    />{' '}
                                                    <span>{t(`protocolTypes.${currentOption?.name}`)}</span>
                                                </p>
                                                <DownArrow />
                                            </>
                                        )}
                                    </div>

                                    {isOpen && (
                                        <ul className={`option_list ${!isEnableProtocolType ? '' : 'disable'}`}>
                                            {optionList?.map((item) => {
                                                return (
                                                    <li
                                                        className={`${protocolType === item?.name ? 'active' : '   '}`}
                                                        onClick={() => HandelChange(item?.name)}
                                                    >
                                                        <img src={item.icon} alt='' />{' '}
                                                        <span style={{ backgroundImage: item?.txtColor }}>
                                                            {t(`protocolTypes.${item?.name}`)}
                                                        </span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </div>
                            </div>

                            <div className='custom_check ml-3  relative  cursor-pointer'>
                                <div
                                    className='flex items-center gap-2'
                                    onClick={() => dispatch(setEnableProtocolType(!isEnableProtocolType))}
                                >
                                    {isEnableProtocolType ? <CheckboxIcon /> : <div className='check_box'> </div>}
                                    <span>{t('self')}</span>
                                </div>

                                <div className='check_tooltip_box'>
                                    {isEnableProtocolType ? (
                                        <p>
                                            {' '}
                                            <b>{t('Disabling Self')}</b> {t('auto_categorising')}
                                        </p>
                                    ) : (
                                        <p>
                                            {' '}
                                            <b>{t('enablingSelf')}</b> {t('categorise_protocol ')}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <Box className='main_box'>
                            {!changeNuancesScreen && !isMetricLoading && (
                                <div id='leftside' style={{ height: TrackHeight }}>
                                    <ReactSlider
                                        className='vertical-slider'
                                        thumbClassName='thumb-container'
                                        trackClassName='example-track'
                                        defaultValue={50}
                                        ariaLabel={'Middle thumb'}
                                        orientation='vertical'
                                        invert
                                        pearling
                                        min={0}
                                        value={triggerNumber}
                                        max={100}
                                        onChange={handleChange}
                                        renderThumb={renderThumb}
                                    />
                                </div>
                            )}

                            {/* when upload show actions buttons  */}
                            <div className='action_box flex mt-2 items-center justify-between'>
                                <div className='relative nuance_info'>
                                    {protocolNuancesLoading || isFetching || changeNuancesScreen || isMetricLoading ? (
                                        <p> {t('update_protocol')}</p>
                                    ) : (
                                        <p> {t('set_nuances_triggers')}</p>
                                    )}
                                    {data?.length == 0 && (
                                        <div className='tooltip_box'>
                                            <p> {t('add_nuance')}</p>
                                        </div>
                                    )}

                                    {data?.length >= 1 && showNuancesMsg && (
                                        <div className='tooltip_box'>
                                            <p> {t('multiple_nuances')}</p>
                                        </div>
                                    )}

                                    {showInput && (
                                        <div className=' tooltip_box'>
                                            <p> {t('nuance_insights')}</p>
                                        </div>
                                    )}
                                </div>

                                <div className='action_button_box flex items-center '>
                                    <div className='refresh_btn_wrapper'>
                                        {!changeNuancesScreen && (
                                            <button
                                                onClick={resetOriginalProtocol}
                                                className={`${
                                                    isChangeProcolNuancesThreshold ? 'disable_btn' : ''
                                                } border-none sidebar_common_btn refresh_btn `}
                                            >
                                                <RefreshIcon />
                                                <div className='tooltip_box'>
                                                    <p> {t('reset')} </p>
                                                </div>
                                            </button>
                                        )}
                                    </div>

                                    {protocolNuancesLoading ||
                                        isFetching ||
                                        isMetricLoading ||
                                        (changeNuancesScreen && (
                                            <>
                                                <div className='refresh_btn_wrapper'>
                                                    <button
                                                        onClick={resetData}
                                                        className={`${
                                                            isChangeProcolNuancesThreshold ? 'disable_btn' : ''
                                                        } border-none sidebar_common_btn refresh_btn `}
                                                    >
                                                        <RefreshIcon />
                                                        <div className='tooltip_box'>
                                                            <p> {t('reset')} </p>
                                                        </div>
                                                    </button>
                                                </div>

                                                <button
                                                    onClick={swapData}
                                                    className=' border-none sidebar_common_btn replace_btn '
                                                >
                                                    <ReplaceIcon />
                                                    <div className='tooltip_box'>
                                                        <p> {t('overwrite')} </p>
                                                    </div>
                                                </button>
                                                <button
                                                    onClick={confirm}
                                                    className='  border-none sidebar_common_btn save_btn '
                                                >
                                                    <SaveIcon className='save_icon' />
                                                    <SaveIconHover className='hover_save' />
                                                    <div className='tooltip_box'>
                                                        <p> {t('confirm')} </p>
                                                    </div>
                                                </button>
                                                <button
                                                    onClick={cancel}
                                                    className=' border-none  sidebar_common_btn  close_icon_btn '
                                                >
                                                    <CloseIcon />
                                                    <div className='tooltip_box'>
                                                        <p> {t('btnNames.cancel')}</p>
                                                    </div>
                                                </button>
                                            </>
                                        ))}
                                </div>
                            </div>

                            <div
                                className={`${
                                    protocolNuancesLoading || isFetching || isMetricLoading
                                        ? 'updated_screen  show_loading'
                                        : changeNuancesScreen
                                        ? 'updated_screen  show_new_screen '
                                        : ''
                                }  ${isEvaluateMode ? 'evaluateMode' : ''} main_content `}
                            >
                                <>
                                    <div className='screen_one'>
                                        {/* vartical slider  */}

                                        <div className='main_slider'>
                                            <p> 100</p>
                                            <div className='main_slider_box' style={{ height: TrackHeight }}>
                                                <div className='slider' style={{ height: TrackHeight }}>
                                                    <div className='track'>
                                                        {protocolThresholdValues.map((value, index) => {
                                                            const updateScreen = changeNuancesScreen
                                                                ? true
                                                                : selectedItemIndex === null
                                                                ? dotHoverIndex === index
                                                                : selectedItemIndex === index
                                                                ? true
                                                                : false;

                                                            return (
                                                                <Draggable
                                                                    onStop={() => {
                                                                        if (checkChangeData && isChangeProtocolData) {
                                                                            updateMetaData();
                                                                        }
                                                                    }}
                                                                    axis='y'
                                                                    bounds={{
                                                                        top: 0,
                                                                        bottom: sliderHeight,
                                                                    }}
                                                                    disabled={
                                                                        selectedItemIndex === null || isDuplicateNuance
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    key={index}
                                                                    position={{
                                                                        x: 0,
                                                                        y: sliderHeight * (1 - value / 100),
                                                                    }}
                                                                    onDrag={(event, data) => {
                                                                        if (isTitleEmpty) {
                                                                            noop(); // Prevent dragging if the title is empty or it's a duplicate nuance
                                                                            dispatch(setCheCkNuancesLeg(true));
                                                                        } else {
                                                                            const dragValue = Math.round(
                                                                                ((sliderHeight - data.y) /
                                                                                    sliderHeight) *
                                                                                    100,
                                                                            );
                                                                            handleChangeThreshold(dragValue);
                                                                        }
                                                                    }}
                                                                >
                                                                    <div
                                                                        className='drag_dot'
                                                                        onClick={() => {
                                                                            if (!isDuplicateNuance) {
                                                                                handleSpanClick(index);
                                                                            }
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setDragSelectVal(false);
                                                                            setDotHoverIndex(null);
                                                                        }}
                                                                        onMouseEnter={() => setDotHoverIndex(index)}
                                                                    >
                                                                        <div className='thumb'> </div>
                                                                        {updateScreen && (
                                                                            <>
                                                                                <div className='dot_info show_num'>
                                                                                    {value}
                                                                                </div>
                                                                                {data[index]?.titles && (
                                                                                    <>
                                                                                        {data[index]?.titles.length >
                                                                                            0 && (
                                                                                            <>
                                                                                                {checkTitleArrayLength(
                                                                                                    data,
                                                                                                ) &&
                                                                                                    selectedItemIndex ===
                                                                                                        index && (
                                                                                                        <button
                                                                                                            className={`${
                                                                                                                isDuplicateNuance
                                                                                                                    ? 'disable_btn'
                                                                                                                    : ''
                                                                                                            } add_info new_add_btn`}
                                                                                                            disabled={
                                                                                                                isDuplicateNuance ||
                                                                                                                nuanceInputValue?.length ===
                                                                                                                    0
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            }
                                                                                                            onClick={(
                                                                                                                event,
                                                                                                            ) =>
                                                                                                                addNuance(
                                                                                                                    event,
                                                                                                                    index,
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <PlusIcon />
                                                                                                        </button>
                                                                                                    )}
                                                                                            </>
                                                                                        )}

                                                                                        <div
                                                                                            className='title_content'
                                                                                            ref={titlesRef}
                                                                                        >
                                                                                            {data[index]?.titles.map(
                                                                                                (title, titleIndex) => {
                                                                                                    const item =
                                                                                                        data[index]; // Current item from `data`
                                                                                                    const copyItem =
                                                                                                        protocolNuancesData.find(
                                                                                                            (copy) =>
                                                                                                                copy.value ===
                                                                                                                item.value,
                                                                                                        ); // Find the matching item by value in `protocolNuancesData`

                                                                                                    const copyTitleExists =
                                                                                                        copyItem?.titles?.includes(
                                                                                                            title,
                                                                                                        ); // Check if the title exists in the corresponding `copyItem`

                                                                                                    // Determine the class to apply based on conditions
                                                                                                    const isNotMatch =
                                                                                                        !copyItem ||
                                                                                                        !title ||
                                                                                                        !copyTitleExists;

                                                                                                    return (
                                                                                                        <div
                                                                                                            key={
                                                                                                                titleIndex
                                                                                                            }
                                                                                                            onClick={(
                                                                                                                event,
                                                                                                            ) =>
                                                                                                                selectTitle(
                                                                                                                    title,
                                                                                                                    titleIndex,
                                                                                                                    index,
                                                                                                                    event,
                                                                                                                )
                                                                                                            }
                                                                                                            className={`titles ${
                                                                                                                isNotMatch
                                                                                                                    ? 'not-match'
                                                                                                                    : ''
                                                                                                            }`} // Add 'not-match' class if title is empty or doesn't match
                                                                                                        >
                                                                                                            <span
                                                                                                                onClick={() => {
                                                                                                                    setSelectedItemIndex(
                                                                                                                        index,
                                                                                                                    );
                                                                                                                }}
                                                                                                                className={`${
                                                                                                                    editTitlePosition ===
                                                                                                                    titleIndex
                                                                                                                        ? 'selected-title'
                                                                                                                        : ''
                                                                                                                } `}
                                                                                                            >
                                                                                                                <label>
                                                                                                                    {
                                                                                                                        title
                                                                                                                    }
                                                                                                                </label>
                                                                                                            </span>
                                                                                                            <button
                                                                                                                className='remove_title'
                                                                                                                onClick={(
                                                                                                                    event,
                                                                                                                ) => {
                                                                                                                    handleRemoveNuance(
                                                                                                                        index,
                                                                                                                        titleIndex,
                                                                                                                        event,
                                                                                                                    );
                                                                                                                    updateMetaData();
                                                                                                                }}
                                                                                                            >
                                                                                                                <CloseIconNuance />
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    );
                                                                                                },
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </Draggable>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <p className={`${changeNuancesScreen ? 'bottom_num' : ''}`}> 0</p>
                                            {changeNuancesScreen && (
                                                <h6>
                                                    {t('latest_saved_edit', { date: formatDateToDDMMYYYY(updateDate) })}
                                                </h6>
                                            )}
                                        </div>

                                        {changeNuancesScreen && (
                                            <NuancesMapping
                                                setNuancesData={setNuancesData}
                                                setNuancesDataValues={setNuancesDataValues}
                                                nuancesDataValues={nuancesDataValues}
                                                fileInfo={fileInfo}
                                                errorMsg={errorMsg}
                                            />
                                        )}

                                        <>
                                            {/* add button and input  */}
                                            <div className={`${showInput && 'show_input'} main_add_detail`}>
                                                {!showInput ? (
                                                    <>
                                                        {checkTitleArrayLength(data) && (
                                                            <button
                                                                className='add_info plus_btn'
                                                                onClick={(event) => {
                                                                    setNuanceInputValue('');
                                                                    handleAddThumb(event);
                                                                }}
                                                            >
                                                                <PlusIcon />
                                                            </button>
                                                        )}

                                                        {!allNuanceLengthTwo && (
                                                            <span className='error_msg text-center mt-5 block  '>
                                                                {t('minimum_nuances')}
                                                            </span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Box className={`${showInput ? 'show_bottom_box' : ''} bottom_box`}>
                                                        <>
                                                            <div
                                                                className='input_box'
                                                                onMouseLeave={handelProtocolType}
                                                            >
                                                                <div>
                                                                    <InputWithLength
                                                                        className='w-full'
                                                                        required
                                                                        maxLength={60}
                                                                        onFocus={() => setInputFocus(true)}
                                                                        value={nuanceInputValue}
                                                                        onChange={nuanceHandleChange}
                                                                        onBlur={() => setInputFocus(false)}
                                                                    />

                                                                    {isDuplicateNuance && (
                                                                        <span className='error_msg mt-0.5'>
                                                                            {t('nuance_already_exists')}
                                                                        </span>
                                                                    )}

                                                                    {nuancesValidate ? (
                                                                        <>
                                                                            {nuancesValidate && (
                                                                                <span className='error_msg'>
                                                                                    {t('minimum_nuances')}
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {checkNuancesLeg && (
                                                                                <span className='error_msg'>
                                                                                    {t('empty_nuances')}
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>

                                                                <Button
                                                                    onClick={() => {
                                                                        checkNuances();
                                                                        updateMetaData();
                                                                    }}
                                                                    className={`${
                                                                        isDuplicateNuance ? 'disable_btn' : ' '
                                                                    } add_btn`}
                                                                >
                                                                    <IconRight />
                                                                </Button>
                                                            </div>

                                                            <div className='eva-threshold'>
                                                                <p>
                                                                    {' '}
                                                                    <FlagIcon /> {t('threshold')}
                                                                </p>
                                                                <span
                                                                    className={`${
                                                                        typeof currentThreshold === 'number' &&
                                                                        currentThreshold > 99
                                                                            ? 'count_limit'
                                                                            : ''
                                                                    } eva-threshold-count`}
                                                                >
                                                                    <button
                                                                        onClick={() => {
                                                                            if (isTitleEmpty) {
                                                                                dispatch(setCheCkNuancesLeg(true));
                                                                            }
                                                                            if (!isTitleEmpty) {
                                                                                handleChangeThreshold(
                                                                                    currentThreshold !== null
                                                                                        ? currentThreshold - 1
                                                                                        : 0,
                                                                                );
                                                                            }
                                                                        }}
                                                                        className={`${
                                                                            isDuplicateNuance ? 'disable_btn' : ''
                                                                        }`}
                                                                    >
                                                                        -
                                                                    </button>

                                                                    <input
                                                                        onFocus={() => setInputFocus(true)}
                                                                        onBlur={() => setInputFocus(false)}
                                                                        className={`${
                                                                            isDuplicateNuance ? 'disable_btn' : ''
                                                                        }`}
                                                                        type='number'
                                                                        value={
                                                                            currentThreshold === null
                                                                                ? ''
                                                                                : currentThreshold.toString()
                                                                        }
                                                                        onChange={(e) => {
                                                                            if (isTitleEmpty) {
                                                                                dispatch(setCheCkNuancesLeg(true));
                                                                            }
                                                                            if (!isTitleEmpty) {
                                                                                handleChangeThreshold(
                                                                                    Number(e.target.value),
                                                                                );
                                                                            }
                                                                        }}
                                                                        onMouseLeave={() => setCheckChangeData(true)}
                                                                    />
                                                                    <button
                                                                        onClick={() => {
                                                                            if (isTitleEmpty) {
                                                                                dispatch(setCheCkNuancesLeg(true));
                                                                            }

                                                                            if (!isTitleEmpty) {
                                                                                handleChangeThreshold(
                                                                                    currentThreshold !== null
                                                                                        ? currentThreshold + 1
                                                                                        : 1,
                                                                                );
                                                                            }
                                                                        }}
                                                                        className={`${
                                                                            isDuplicateNuance ? 'disable_btn' : ''
                                                                        }`}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </>
                                                    </Box>
                                                )}
                                            </div>
                                        </>
                                    </div>

                                    <div className='loading_screen'>
                                        <div className='skeleton1'>
                                            <Skeleton />
                                        </div>
                                        <div className='skeleton2'>
                                            <Skeleton />
                                        </div>
                                        <div className='skeleton3'>
                                            <Skeleton />
                                        </div>
                                    </div>
                                </>
                            </div>
                        </Box>
                        <div className='flex   justify-end items-center w-50 bottom_arrow_box gap-3'>
                            <div className='arrow_btn_box flex justify-center gap-5  items-center'>
                                <button
                                    onClick={handleLeftClick}
                                    data-tooltip-id='left_arrow'
                                    className={`${
                                        protocolIndex === 0 ? 'arrow_disable' : ''
                                    } arrow_btn  flex justify-center  items-center`}
                                    disabled={protocolIndex === 0 ? true : false}
                                >
                                    <LeftArrow />
                                    <Tooltip id='left_arrow' offset={5} place='top' className='tooltip-box show_arrow'>
                                        <p> {t('btnNames.prev')} </p>
                                    </Tooltip>
                                </button>

                                <button
                                    onClick={handleRightClick}
                                    className={`${
                                        protocolIndex === metaMarkerLength - 1 || protocolIndex === null
                                            ? 'arrow_disable'
                                            : ''
                                    } arrow_btn  flex justify-center   items-center`}
                                    disabled={
                                        protocolIndex === metaMarkerLength - 1 || protocolIndex === null ? true : false
                                    }
                                    data-tooltip-id='right_arrow'
                                >
                                    <RightArrow />
                                    <Tooltip id='right_arrow' offset={5} place='top' className='tooltip-box show_arrow'>
                                        <p> {t('btnNames.next')} </p>
                                    </Tooltip>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
};

export default Sidebar;
