import { useEffect, useState } from 'react';

import './ProtocolDetail.css';

import clsx from 'clsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as LeftArrow } from '../../../../../../../../src/assets/icons/Balck-Arrow-left.svg';
import { ReactComponent as RightArrow } from '../../../../../../../../src/assets/icons/Balck-Right-left.svg';
import { ReactComponent as EditBtn } from '../../../../../../../../src/assets/icons/Edit_Icon.svg';
import { ReactComponent as FlagIcon } from '../../../../../../../../src/assets/icons/FillFlag.svg';
import { ReactComponent as MetricsIcon } from '../../../../../../../../src/assets/icons/icon-metrics.svg';
import { ReactComponent as CloseEye } from '../../../../../../../../src/assets/icons/icon-purple-eye-closed.svg';
import { ReactComponent as CloseEyeWhite } from '../../../../../../../../src/assets/icons/icon-white-eye-closed.svg';
import { ReactComponent as LeftArrows } from '../../../../../../../../src/assets/icons/leftArrow.svg';
import PolicyFill from '../../../../../../../../src/assets/icons/PolicyFill.svg';
import { appInsights } from '../../../../../../../AppInsights';
import { CreateEvaModalOpen } from '../../../../../../../contexts/CreateEvaModalContext';
import {
    setEvaluateMode,
    setHoverProtocolIndex,
    setLeftSideGraphProtocolData,
    setMetricsMode,
    setProtocolEditMode,
    setProtocolIndex,
} from '../../../../../../../Redux/EvaInfo';
import { RootState } from '../../../../../../../Redux/store';
import { EvaService } from '../../../../../../../services/eva/eva.service';
import { EvaType, MetaMarkerItem } from '../../../types';
import { convertToThumbData } from '../../createEva/EvaCompare';
import { ErrorComponent } from '../../error';
import { OptionData } from '../../ProtocolTypeList';
import MarkdownRenderer from '../OversightSummaries/MarkdownRender';
import ProtocolChart from './ProtocolChart';

interface ProtocolDetailProps {}

const ProtocolDetail: React.FC<ProtocolDetailProps> = () => {
    const { t } = useTranslation();
    const protocolIndex = useSelector((state: RootState) => state.EvaInfo.protocolIndex);
    const publishProtocols = useSelector((state: RootState) => state.EvaInfo.publishProtocols);
    const draftProtocols = useSelector((state: RootState) => state.EvaInfo.draftProtocols);
    const isOpenBluePrintModal = useSelector((state: RootState) => state.EvaInfo.isOpenBluePrintModal);
    const isMetricsMode = useSelector((state: RootState) => state.EvaInfo.isMetricsMode);
    const isEvaluateMode = useSelector((state: RootState) => state.EvaInfo.isEvaluateMode);
    const evaId = useSelector((state: RootState) => state.EvaInfo.evaId);
    const isProtocolEditMode = useSelector((state: RootState) => state.EvaInfo.isProtocolEditMode);

    const { setCreateEvaModalOpen } = CreateEvaModalOpen();

    const evaService = new EvaService();

    const timeZoneOffset = moment().utcOffset().toString();

    const timeFilter = 'Week';

    const protocolsData = isOpenBluePrintModal ? draftProtocols : publishProtocols;

    const singeProtocolObj = protocolsData.find(
        (item: MetaMarkerItem, index: number | null | undefined) => index === protocolIndex,
    );

    // get protocol type icon , bgcolor
    const getProcolTypeObj = OptionData.find((item, index) => item.name == singeProtocolObj?.protocolType);

    //  convert nunaces data

    const getNuancesVal = singeProtocolObj ? singeProtocolObj.nuances : [];
    const protocolNuancesData = convertToThumbData(getNuancesVal);

    const categories = {
        Low: 0,
        Minor: 0,
        Medium: 0,
        Significant: 0,
        High: 0,
    };
    // Categorize and count nuances
    protocolNuancesData.forEach((item) => {
        const value = item.value;
        if (value >= 0 && value <= 20) {
            categories.Low++;
        } else if (value >= 21 && value <= 40) {
            categories.Minor++;
        } else if (value >= 41 && value <= 60) {
            categories.Medium++;
        } else if (value >= 61 && value <= 80) {
            categories.Significant++;
        } else if (value >= 81 && value <= 100) {
            categories.High++;
        }
    });

    const {
        isLoading: loading,
        isFetching: fetching,
        isError,
    } = useQuery(
        ['eva-protocol', evaId, timeFilter, timeZoneOffset],
        async () => {
            if (evaId) {
                try {
                    const data = await evaService.getEvaProtocolLog(evaId, timeFilter, timeZoneOffset, '');
                    dispatch(setLeftSideGraphProtocolData(data));
                } catch (e) {
                    console.error(e);
                    if (e instanceof Error) {
                        appInsights.trackException({ error: e });
                    }
                    throw e;
                }
            }
            return null;
        },
        {
            enabled: !isOpenBluePrintModal ? true : false,
        },
    );

    useEffect(() => {
        if (isOpenBluePrintModal) {
            document.documentElement.id = 'edit-mode';
        } else {
            document.documentElement.id = 'ctrl';
        }
    }, [isOpenBluePrintModal]);

    const thresholds = getNuancesVal.map((item) => item.threshold);
    // Find lowest and highest
    const lowestThresholds = Math.min(...thresholds);
    const highestThresholds = Math.max(...thresholds);
    // averageThreshold
    const totalThreshold = getNuancesVal.reduce((sum, item) => sum + item.threshold, 0);
    const averageThreshold = totalThreshold / getNuancesVal.length;

    const metaMarkerLength = protocolsData.length;
    const dispatch = useDispatch();

    // change a protocol serial wise bottom left arrow click
    const handleLeftClick = () => {
        if (protocolIndex !== undefined) {
            const newIndex = protocolIndex === null ? metaMarkerLength - 1 : protocolIndex > 0 ? protocolIndex - 1 : 0;
            dispatch(setProtocolIndex(newIndex));
        }
    };

    const handleRightClick = () => {
        if (protocolIndex !== undefined) {
            const newIndex =
                protocolIndex === null ? 1 : protocolIndex < metaMarkerLength - 1 ? protocolIndex + 1 : protocolIndex;
            dispatch(setProtocolIndex(newIndex));
        }
    };
    useEffect(() => {
        dispatch(setHoverProtocolIndex(null));
    }, [dispatch]);

    if (isError) {
        return (
            <div className='h-full'>
                <ErrorComponent />
            </div>
        );
    }

    return (
        <div
            className={` ${isOpenBluePrintModal ? 'protocol_detail_edit_mode' : ' h-full'}   ${
                isProtocolEditMode ? 'protocol_edit_mode ' : ''
            } `}
        >
            <div className='protocol_detail  '>
                {singeProtocolObj ? (
                    <div className='protocol_heading flex justify-between items-center'>
                        <>
                            <div className='flex items-center gap-3'>
                                <div
                                    className='cursor-pointer'
                                    onClick={() => {
                                        dispatch(setProtocolIndex(null));
                                    }}
                                >
                                    <LeftArrows />
                                </div>

                                <div className='flex gap-3 items-center'>
                                    <div className='round flex justify-center items-center'>
                                        <div className='circle' style={{ backgroundColor: singeProtocolObj?.color }} />
                                    </div>

                                    {<h5> {singeProtocolObj?.protocolIdentification?.protocol_name} </h5>}
                                </div>
                            </div>

                            <div className='flex items-center gap-5 cursor-pointer'>
                                <div
                                    className='edit_btn'
                                    onClick={() => {
                                        {
                                            if (isOpenBluePrintModal) {
                                                dispatch(setProtocolEditMode(true));
                                                dispatch(setProtocolIndex(protocolIndex));
                                            } else {
                                                setCreateEvaModalOpen(true);
                                                setTimeout(() => {
                                                    dispatch(setProtocolEditMode(true));
                                                    dispatch(setProtocolIndex(protocolIndex));
                                                }, 100);
                                            }
                                        }
                                    }}
                                >
                                    <EditBtn />
                                </div>
                            </div>
                        </>
                    </div>
                ) : (
                    <Skeleton className='skeleton_bg protocol_heading w-full' height={50} />
                )}

                {singeProtocolObj ? (
                    <>
                        {isOpenBluePrintModal ? (
                            <div className='top_date_box flex items-center justify-between '>
                                <>
                                    <div className='flex items-center gap-4'>
                                        <Tooltip
                                            id='evaluateMode'
                                            offset={5}
                                            place='top'
                                            className='tooltip-box show_arrow'
                                        >
                                            <p> {t('evaluate')} </p>
                                        </Tooltip>
                                        <Tooltip
                                            id='metricsMode'
                                            offset={5}
                                            place='top'
                                            className='tooltip-box show_arrow'
                                        >
                                            <p> {t('metrics')} </p>
                                        </Tooltip>

                                        <button
                                            data-tooltip-id='evaluateMode'
                                            className='rsp-icon eay_btn relative'
                                            onClick={() => {
                                                {
                                                    dispatch(setEvaluateMode(!isEvaluateMode));
                                                    dispatch(setMetricsMode(false));
                                                }
                                            }}
                                        >
                                            {isEvaluateMode ? (
                                                <div className='eva-test-eye-icon-active'>
                                                    <CloseEyeWhite
                                                        width={15}
                                                        height={15}
                                                        fill='rgba(96, 244, 200, 0.75)'
                                                    />
                                                </div>
                                            ) : (
                                                <CloseEye width={15} height={15} fill='rgba(111, 191, 225, 0.75)' />
                                            )}
                                        </button>
                                        <button
                                            data-tooltip-id='metricsMode'
                                            className={clsx(
                                                'rsp-icon relative eay_btn',
                                                isMetricsMode && 'rsp-icon-selected',
                                            )}
                                            onClick={() => {
                                                {
                                                    dispatch(setMetricsMode(!isMetricsMode));
                                                    dispatch(setEvaluateMode(false));
                                                }
                                            }}
                                        >
                                            {/* {showNotification && <div className='metric-notification' />} */}
                                            <MetricsIcon className='w-3 h-3' fill={'rgba(111, 191, 225, 0.75)'} />
                                        </button>
                                    </div>
                                    <span>
                                        {t('creation_date', {
                                            date: moment(singeProtocolObj?.protocolIdentification?.created_at).format(
                                                'DD/MM/YY',
                                            ),
                                        })}
                                    </span>
                                </>
                            </div>
                        ) : (
                            <div className='top_date_box flex items-center justify-end '>
                                <span>
                                    {t('creation_date', {
                                        date: moment(singeProtocolObj?.protocolIdentification?.created_at).format(
                                            'DD/MM/YY',
                                        ),
                                    })}
                                </span>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <Skeleton className='skeleton_bg protocol_heading w-full' height={45} />
                        <hr />
                    </>
                )}

                <Tooltip
                    id='protocolName'
                    offset={5}
                    place='top-start'
                    className='tooltip-box protocol_name_tooltips show_arrow'
                >
                    <p> {OptionData.find((option) => option.name === getProcolTypeObj?.name)?.description} </p>
                </Tooltip>
                <div className='ctrl_scroll'>
                    <div className='content  '>
                        {singeProtocolObj ? (
                            <div className='protocol_text mb-2 flex gap-2 items-center' data-tooltip-id='protocolName'>
                                <img src={getProcolTypeObj?.transparentIcon} alt={PolicyFill} />
                                {t(`protocolTypes.${getProcolTypeObj?.name}`)}
                            </div>
                        ) : (
                            <Skeleton className='skeleton_bg mb-2' width={150} height={30} />
                        )}

                        {singeProtocolObj ? (
                            <div className='description top_description executive_summaries_main mb-2'>
                                <div className='mini_content'>
                                    <MarkdownRenderer>
                                        {singeProtocolObj?.protocolIdentification?.description ?? ''}
                                    </MarkdownRenderer>
                                </div>
                            </div>
                        ) : (
                            <Skeleton className='skeleton_bg mb-2' height={150} />
                        )}

                        {singeProtocolObj ? (
                            <div className='description sub_information  executive_summaries_main pb-0'>
                                <h2 className='mb-2'>{t('protocolDetail.mechanism')}</h2>

                                <div className='sub_content'>
                                    <MarkdownRenderer>
                                        {singeProtocolObj?.protocolIdentification?.mechanism ?? ''}
                                    </MarkdownRenderer>
                                </div>

                                <div className='nuanced gap-2 flex justify-between items-center my-4 '>
                                    <span className='low'>1</span>
                                    <div className='relative w-full'>
                                        <div className='nuance_bar relative flex '>
                                            {protocolNuancesData.map((obj, index) => {
                                                return (
                                                    <span
                                                        style={{
                                                            left: `calc(${obj.value}% - 2.5px)`,
                                                        }}
                                                        className='nuance'
                                                    />
                                                );
                                            })}
                                            <span
                                                className='trigger_overly absolute'
                                                style={{ width: `${singeProtocolObj?.triggerNumber}%` }}
                                            />
                                            <div
                                                className='trigger_icon items-center flex justify-center gap-1'
                                                style={{
                                                    left: `calc(${singeProtocolObj?.triggerNumber}%)`,
                                                    transform: `translateX(-${50}%)`, // Corrected syntax
                                                }}
                                            >
                                                <FlagIcon />

                                                <div className='icons'>
                                                    {singeProtocolObj.triggerState === '>' && <RightArrow />}
                                                    {singeProtocolObj.triggerState === '<' && <LeftArrow />}
                                                </div>

                                                {/* <ProtocolTrigger /> */}
                                            </div>
                                        </div>
                                    </div>

                                    <span className='low heigh'>100</span>
                                </div>

                                <div className='average_box gap-2 mt-3 flex flex-wrap justify-center items-center'>
                                    <label data-tooltip-id='averageweight'>
                                        {averageThreshold.toFixed(2)}
                                        <Tooltip
                                            id='averageweight'
                                            offset={5}
                                            place='top'
                                            className='tooltip-box show_arrow'
                                        >
                                            <p> {t('protocolDetail.averageWeight')} </p>
                                        </Tooltip>
                                    </label>
                                    <label data-tooltip-id='lowest'>
                                        {lowestThresholds}
                                        <Tooltip id='lowest' offset={5} place='top' className='tooltip-box show_arrow'>
                                            <p>{t('protocolDetail.lowestNuance')} </p>
                                        </Tooltip>
                                    </label>
                                    <label data-tooltip-id='highest'>
                                        {highestThresholds}
                                        <Tooltip id='highest' offset={5} place='top' className='tooltip-box show_arrow'>
                                            <p> {t('protocolDetail.highestNuance')} </p>
                                        </Tooltip>
                                    </label>
                                </div>

                                <div className='sensitivity  mt-3'>
                                    <h2 className='mb-3'>{t('protocolDetail.sensitivity')}</h2>
                                    <div className='average_box gap-2 mt-3 flex flex-wrap justify-center items-center'>
                                        <label className='flex gap-2 items-center justify-center'>
                                            {t('protocolDetail.low')} <span> {categories?.Low} </span>
                                        </label>
                                        <label className='flex gap-2  items-center justify-center'>
                                            {t('protocolDetail.minor')} <span> {categories?.Minor} </span>
                                        </label>
                                        <label className='flex gap-2  items-center justify-center'>
                                            {t('protocolDetail.medium')} <span> {categories?.Medium} </span>
                                        </label>
                                        <label className='flex gap-2 active items-center justify-center'>
                                            {t('protocolDetail.significant')} <span> {categories?.Significant} </span>
                                        </label>
                                        <label className='flex gap-2 items-center justify-center'>
                                            {t('protocolDetail.high')} <span> {categories?.High} </span>
                                        </label>
                                    </div>
                                </div>

                                {!isOpenBluePrintModal && (
                                    <div className='mt-5 '>
                                        <ProtocolChart timeFilter={timeFilter} singeProtocolObj={singeProtocolObj} />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Skeleton className='skeleton_bg mb-2' height={400} />
                        )}

                        {singeProtocolObj ? (
                            <div className='flex justify-end items-center w-50 bottom_arrow_box gap-3'>
                                <div className='arrow_btn_box flex justify-center gap-5  items-center'>
                                    <button
                                        onClick={handleLeftClick}
                                        data-tooltip-id='left_arrow'
                                        className={`${
                                            protocolIndex === 0 ? 'arrow_disable' : ''
                                        } arrow_btn  flex justify-center  items-center`}
                                        disabled={protocolIndex === 0 ? true : false}
                                    >
                                        <LeftArrow />
                                        <Tooltip
                                            id='left_arrow'
                                            offset={5}
                                            place='top'
                                            className='tooltip-box show_arrow'
                                        >
                                            <p> {t('btnNames.prev')} </p>
                                        </Tooltip>
                                    </button>

                                    <button
                                        onClick={handleRightClick}
                                        className={`${
                                            protocolIndex === metaMarkerLength - 1 || protocolIndex === null
                                                ? 'arrow_disable'
                                                : ''
                                        } arrow_btn  flex justify-center   items-center`}
                                        disabled={
                                            protocolIndex === metaMarkerLength - 1 || protocolIndex === null
                                                ? true
                                                : false
                                        }
                                        data-tooltip-id='right_arrow'
                                    >
                                        <RightArrow />
                                        <Tooltip
                                            id='right_arrow'
                                            offset={5}
                                            place='top'
                                            className='tooltip-box show_arrow'
                                        >
                                            <p> {t('btnNames.next')} </p>
                                        </Tooltip>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className='w-full flex items-center justify-center'>
                                <Skeleton className='skeleton_bg mx-auto mb-2' width={100} height={30} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProtocolDetail;
