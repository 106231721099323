import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MetaMarkerItem, Nuances, ThumbData } from '../components/organisation/copilotOrganisation/eva/types';

type PercentageChange = {
    type: string;
    percentage: number;
    count: number;
};

type Protocol = {
    protocolName: string;
    data: [];
    color: string;
    type: string;
    triggerNumber: number;
    triggerState: '>' | '<' | '>=' | '<=' | '==';
    totalTrigger: number;
};

type LeftSideGraphProtocolType = {
    percentageChange: PercentageChange[];
    protocols: Protocol[];
    dateRange: string[];
    averageProtocolTrigger: number[];
};

interface EvaState {
    isCreateEvaFromBlueprints: boolean;
    publishProtocols: MetaMarkerItem[];
    draftProtocols: MetaMarkerItem[];
    protocolIndex: number | null;
    hoverProtocolIndex: number | null;
    isOpenBluePrintModal: boolean;
    isProtocolEditMode: boolean;
    isMetricsMode: boolean;
    isEvaluateMode: boolean;
    isActivityMode: boolean;
    nuancesWithThreshold: Nuances[];
    selectedCurrentThresholdIndex: number | null;
    nuancesValidate: boolean;
    isProtocolValidate: boolean;
    checkEmptyNuances: boolean;
    protocolThresholdValues: number[];
    triggerNumber: number;
    copyTriggerNumber: number;
    protocolName: string;
    copyOfProtocolName: string;
    copyOfProtocolType: string;
    evaTitle: string;
    triggerIcon: string;
    copyOfTriggerIcon: string;
    isOpenConfirmModal: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    evaProtocolsData: any[];
    sliderNuancesData: ThumbData[];
    evaId: null | string;
    isOpenLeftSidePanel: string;
    protocolType: string;
    metaMarkerValidation: boolean;
    checkNuancesLeg: boolean;
    isEnableProtocolType: boolean;
    isCopyEnableProtocolType: boolean;
    formattedData: Nuances[];
    currentProtocolNuances: Nuances[];
    metricProtocolLoading: boolean;
    saveConfirmModal: boolean;
    isChangeProtocolData: boolean;
    checkConfirmationScreen: boolean;
    updateDate: string;
    orbitLoader: boolean;
    changeNuancesScreen: boolean;
    apiNuances: Nuances[];
    isDeleteProtocol: boolean;
    SaveProtocolEvent: unknown | null;
    addNewProtocol: unknown | null;
    fileError: string | null;
    protocolErrorMessage: string | null;
    leftSideGraphProtocol: LeftSideGraphProtocolType;
    isFetchError: boolean;
    addProtocolLoading: boolean;
    templateEvaLoading: boolean;
    bluePrintEvaLoading: boolean;
    unarchiveLoading: boolean;
    archiveEvaId: null | string;
    removeArchiveEvaIdConfirmation: boolean;
    restartEvaLoading: boolean;
    updateEvaLoading: boolean;
    uploadFileLoading: boolean;
}

const initialState: EvaState = {
    isCreateEvaFromBlueprints: false,
    publishProtocols: [],
    draftProtocols: [],
    protocolIndex: null,
    isOpenBluePrintModal: false,
    hoverProtocolIndex: null,
    isProtocolEditMode: false,
    isMetricsMode: false,
    isEvaluateMode: false,
    isActivityMode: false,
    nuancesWithThreshold: [],
    selectedCurrentThresholdIndex: null,
    nuancesValidate: false,
    isProtocolValidate: false,
    checkEmptyNuances: false,
    protocolThresholdValues: [],
    triggerNumber: 50,
    copyTriggerNumber: 50,
    protocolName: '',
    copyOfProtocolName: 'test card',
    protocolType: 'Custom',
    copyOfProtocolType: 'Custom',
    evaTitle: '',
    triggerIcon: '>',
    copyOfTriggerIcon: '>',
    isOpenConfirmModal: false,
    evaProtocolsData: [],
    sliderNuancesData: [],
    evaId: null,
    isOpenLeftSidePanel: '',
    metaMarkerValidation: false,
    checkNuancesLeg: false,
    isEnableProtocolType: true,
    isCopyEnableProtocolType: true,
    formattedData: [],
    currentProtocolNuances: [],
    metricProtocolLoading: false,
    saveConfirmModal: false,
    isChangeProtocolData: false,
    checkConfirmationScreen: false,
    updateDate: '',
    orbitLoader: false,
    changeNuancesScreen: false,
    apiNuances: [],
    isDeleteProtocol: false,
    SaveProtocolEvent: null,
    addNewProtocol: null,
    fileError: null,
    protocolErrorMessage: null,
    leftSideGraphProtocol: {
        percentageChange: [],
        protocols: [],
        dateRange: [],
        averageProtocolTrigger: [],
    },
    isFetchError: false,
    addProtocolLoading: false,
    templateEvaLoading: false,
    bluePrintEvaLoading: false,
    unarchiveLoading: false,
    archiveEvaId: localStorage.getItem('archiveEvaId') || null,
    removeArchiveEvaIdConfirmation: false,
    restartEvaLoading: false,
    updateEvaLoading: false,
    uploadFileLoading: false,
};

const EvaInfo = createSlice({
    name: 'EvaInfo',
    initialState,
    reducers: {
        SetIsCreateEvaFromBlueprints: (state, action: PayloadAction<boolean>) => {
            state.isCreateEvaFromBlueprints = action.payload;
        },
        setPublishProtocols: (state, action: PayloadAction<MetaMarkerItem[]>) => {
            state.publishProtocols = action.payload;
        },
        setDraftProtocols: (state, action: PayloadAction<MetaMarkerItem[]>) => {
            state.draftProtocols = action.payload;
        },
        setProtocolIndex: (state, action: PayloadAction<number | null>) => {
            state.protocolIndex = action.payload;
        },
        setHoverProtocolIndex: (state, action: PayloadAction<number | null>) => {
            state.hoverProtocolIndex = action.payload;
        },
        setOpenBluePrintModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenBluePrintModal = action.payload;
        },
        setProtocolEditMode: (state, action: PayloadAction<boolean>) => {
            state.isProtocolEditMode = action.payload;
        },
        setMetricsMode: (state, action: PayloadAction<boolean>) => {
            state.isMetricsMode = action.payload;
        },
        setEvaluateMode: (state, action: PayloadAction<boolean>) => {
            state.isEvaluateMode = action.payload;
        },
        setActivityMode: (state, action: PayloadAction<boolean>) => {
            state.isActivityMode = action.payload;
        },
        setNuancesWithThreshold: (state, action: PayloadAction<Nuances[]>) => {
            state.nuancesWithThreshold = action.payload;
        },
        setSelectedCurrentThresholdIndex: (state, action: PayloadAction<number | null>) => {
            state.selectedCurrentThresholdIndex = action.payload;
        },
        setNuancesValidate: (state, action: PayloadAction<boolean>) => {
            state.nuancesValidate = action.payload;
        },

        setProtocolValidate: (state, action: PayloadAction<boolean>) => {
            state.isProtocolValidate = action.payload;
        },

        setCheckEmptyNuances: (state, action: PayloadAction<boolean>) => {
            state.checkEmptyNuances = action.payload;
        },
        setProtocolThresholdValues: (state, action: PayloadAction<number[]>) => {
            state.protocolThresholdValues = action.payload;
        },
        setTriggerNumber: (state, action: PayloadAction<number>) => {
            state.triggerNumber = action.payload;
        },
        setCopyTriggerNumber: (state, action: PayloadAction<number>) => {
            state.copyTriggerNumber = action.payload;
        },
        setProtocolName: (state, action: PayloadAction<string>) => {
            state.protocolName = action.payload;
        },
        setCopyOfProtocolName: (state, action: PayloadAction<string>) => {
            state.copyOfProtocolName = action.payload;
        },
        setEvaTitle: (state, action: PayloadAction<string>) => {
            state.evaTitle = action.payload;
        },
        setTriggerIcon: (state, action: PayloadAction<string>) => {
            state.triggerIcon = action.payload;
        },
        setCopyTriggerIcon: (state, action: PayloadAction<string>) => {
            state.copyOfTriggerIcon = action.payload;
        },

        setIsOpenConfirmModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenConfirmModal = action.payload;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setEvaProtocolsData: (state, action: PayloadAction<any[]>) => {
            state.evaProtocolsData = action.payload;
        },
        setSliderNuancesData: (state, action: PayloadAction<ThumbData[]>) => {
            state.sliderNuancesData = action.payload;
        },

        getEvaId: (state, action: PayloadAction<null | string>) => {
            state.evaId = action.payload;
        },
        setOpenLeftSidePanel: (state, action: PayloadAction<string>) => {
            state.isOpenLeftSidePanel = action.payload;
        },
        setProtocolType: (state, action: PayloadAction<string>) => {
            state.protocolType = action.payload;
        },
        setCopyOfProtocolType: (state, action: PayloadAction<string>) => {
            state.copyOfProtocolType = action.payload;
        },
        setMetaMarkerValidation: (state, action: PayloadAction<boolean>) => {
            state.metaMarkerValidation = action.payload;
        },
        setCheCkNuancesLeg: (state, action: PayloadAction<boolean>) => {
            state.checkNuancesLeg = action.payload;
        },
        setEnableProtocolType: (state, action: PayloadAction<boolean>) => {
            state.isEnableProtocolType = action.payload;
        },
        setCopyEnableProtocolType: (state, action: PayloadAction<boolean>) => {
            state.isCopyEnableProtocolType = action.payload;
        },
        setCurrentProtocolNuances: (state, action: PayloadAction<Nuances[]>) => {
            state.currentProtocolNuances = action.payload;
        },
        setMetricProtocolLoading: (state, action: PayloadAction<boolean>) => {
            state.metricProtocolLoading = action.payload;
        },
        setSaveConfirmModal: (state, action: PayloadAction<boolean>) => {
            state.saveConfirmModal = action.payload;
        },
        setIsChangeProtocolData: (state, action: PayloadAction<boolean>) => {
            state.isChangeProtocolData = action.payload;
        },
        setConfirmationScreen: (state, action: PayloadAction<boolean>) => {
            state.checkConfirmationScreen = action.payload;
        },
        setUpdateData: (state, action: PayloadAction<string>) => {
            state.updateDate = action.payload;
        },
        setOrbitLoader: (state, action: PayloadAction<boolean>) => {
            state.orbitLoader = action.payload;
        },
        setChangeNuancesScreen: (state, action: PayloadAction<boolean>) => {
            state.changeNuancesScreen = action.payload;
        },
        setApiNuances: (state, action: PayloadAction<Nuances[]>) => {
            state.apiNuances = action.payload;
        },
        setDeleteProtocol: (state, action: PayloadAction<boolean>) => {
            state.isDeleteProtocol = action.payload;
        },
        setSaveProtocolEvent: (state, action: PayloadAction<React.MouseEvent<HTMLElement, MouseEvent> | null>) => {
            state.SaveProtocolEvent = action.payload;
        },
        setAddNewProtocol: (state, action: PayloadAction<React.MouseEvent<HTMLElement, MouseEvent> | null>) => {
            state.addNewProtocol = action.payload;
        },
        setFileError: (state, action: PayloadAction<string | null>) => {
            state.fileError = action.payload;
        },
        setProtocolErrorMessage: (state, action: PayloadAction<string | null>) => {
            state.protocolErrorMessage = action.payload;
        },
        setLeftSideGraphProtocolData: (state, action: PayloadAction<LeftSideGraphProtocolType>) => {
            state.leftSideGraphProtocol = action.payload;
        },
        setFetchError: (state, action: PayloadAction<boolean>) => {
            state.isFetchError = action.payload;
        },
        setAddProtocolLoading: (state, action: PayloadAction<boolean>) => {
            state.addProtocolLoading = action.payload;
        },
        setTemplateEvaLoading: (state, action: PayloadAction<boolean>) => {
            state.templateEvaLoading = action.payload;
        },
        setBluePrintEvaLoading: (state, action: PayloadAction<boolean>) => {
            state.bluePrintEvaLoading = action.payload;
        },
        setUnarchiveLoading: (state, action: PayloadAction<boolean>) => {
            state.unarchiveLoading = action.payload;
        },
        setArchiveEvaId: (state, action: PayloadAction<null | string>) => {
            state.archiveEvaId = action.payload;
            if (action.payload) {
                localStorage.setItem('archiveEvaId', action.payload);
            } else {
                localStorage.removeItem('archiveEvaId');
            }
        },
        setRemoveArchiveEvaIdConfirmation: (state, action: PayloadAction<boolean>) => {
            state.removeArchiveEvaIdConfirmation = action.payload;
        },
        setRestartEvaLoading: (state, action: PayloadAction<boolean>) => {
            state.restartEvaLoading = action.payload;
        },
        setUpdateEvaLoading: (state, action: PayloadAction<boolean>) => {
            state.updateEvaLoading = action.payload;
        },
        setUploadFileLoading: (state, action: PayloadAction<boolean>) => {
            state.uploadFileLoading = action.payload;
        },
    },
});

export const {
    SetIsCreateEvaFromBlueprints,
    setMetricsMode,
    setActivityMode,
    setProtocolEditMode,
    setEvaluateMode,
    setDraftProtocols,
    setOpenBluePrintModal,
    setHoverProtocolIndex,
    setPublishProtocols,
    setProtocolIndex,
    setNuancesWithThreshold,
    setSelectedCurrentThresholdIndex,
    setNuancesValidate,
    setCheCkNuancesLeg,
    setEnableProtocolType,
    setProtocolValidate,
    setCheckEmptyNuances,
    setProtocolThresholdValues,
    setCopyEnableProtocolType,
    setProtocolName,
    setProtocolType,
    setCopyOfProtocolName,
    setCopyOfProtocolType,
    setCopyTriggerNumber,
    setTriggerNumber,
    setEvaTitle,
    setTriggerIcon,
    setCopyTriggerIcon,
    setIsOpenConfirmModal,
    setEvaProtocolsData,
    setSliderNuancesData,
    getEvaId,
    setOpenLeftSidePanel,
    setMetaMarkerValidation,
    setCurrentProtocolNuances,
    setMetricProtocolLoading,
    setSaveConfirmModal,
    setIsChangeProtocolData,
    setConfirmationScreen,
    setUpdateData,
    setOrbitLoader,
    setAddNewProtocol,
    setSaveProtocolEvent,
    setChangeNuancesScreen,
    setApiNuances,
    setDeleteProtocol,
    setFileError,
    setProtocolErrorMessage,
    setLeftSideGraphProtocolData,
    setFetchError,
    setAddProtocolLoading,
    setTemplateEvaLoading,
    setBluePrintEvaLoading,
    setArchiveEvaId,
    setRemoveArchiveEvaIdConfirmation,
    setUnarchiveLoading,
    setRestartEvaLoading,
    setUpdateEvaLoading,
    setUploadFileLoading,
} = EvaInfo.actions;

export default EvaInfo.reducer;
