export type DataPoint = {
    id: number;
    x: number;
    y: number;
    name: string;
};
export type GraphProps = {
    data: DataPoint[];
    width?: number;
    height?: number;
};

export type GraphPoints = {
    name: string;
    date: string;
    score: number;
};

export type EvaGraphProps = {
    data: GraphPoints[];
};

export interface Nuances {
    nuance: string;
    threshold: number;
}

export interface Gradients {
    name: string;
    number: number;
}

export interface ProtocolIdentification {
    description: string | null;
    id: string;
    mechanism: string | null;
    created_at: string;
    protocol_name: string;
    sensitivity: string | null;
}

export interface MetaMarkerItem {
    metaMarker: string;
    triggerNumber: number;
    triggerState: string | '<' | '>';
    weight: number;
    nuances: Nuances[];
    protocolIdentification: ProtocolIdentification | null;
    triggerCount: number;
    gradients?: Gradients[];
    color?: string;
    protocolType?: string;
    isUpdate: boolean;
    isSelfCategorization?: boolean;
    indexNum: number;
}

export enum EvaType {
    draft = 'draft',
    publish = 'publish',
}

export enum EvaCreationMethod {
    Blank = 'blank',
    Template = 'template',
    Cloud = 'cloud',
}

export interface MetaMarkerData {
    title: string;
    description: string;
    saveType: EvaType;
    gradients?: Gradients[];
    metaMarkers: MetaMarkerItem[];
    isTestMode: boolean;
    blueprintJson?: string;
    templateId?: string;
    creationMethod?: EvaCreationMethod;
}

export interface protocolListData {
    protocolName: string;
    protocolType: string;
    color: string;
    type: string;
    triggerNumber: number;
    totalTrigger: number;
    triggerState: string;
}

export interface GetEva {
    id: string;
    evaNanoid: string;
    title: string;
    description: string;
    metaMarkers: MetaMarkerItem[];
    isPublished: boolean;
    isTested: boolean;
    version?: number;
}

export interface TemplateCategory {
    id: string;
    name: string;
}

export interface TemplateBlueprint {
    id: string;
    name: string;
    description: string;
    isShieldSubscription: boolean;
    evaBlueprintImage?: string;
    blueprintJSON?: string;
}

export interface ThumbData {
    value: number;
    titles: string[];
}

export enum SubScriptionType {
    SHIELD = 'SHIELD',
    OVERSIGHT = 'OVERSIGHT',
}

export interface percentageChangeType {
    currentDayPercent: number;
    previousDayDifference: number;
}

export interface Domain {
    name: string;
    emoji: string;
}

export interface EvaNuances {
    metaMarker: string;
    nuances: Nuances[];
}

export interface EvaProtocolType {
    protocolMetaMarker: Record<string, Record<string, number>[]>;
}

export interface ProtocolOptionType {
    name: ProtocolTypes;
    icon: string;
    linearBg: string;
    txtColor: string;
    description: string;
    fillIcon: string;
    lineColor: string;
    bgColor: string;
    transparentIcon: string;
}

export interface GraphProtocol {
    name: string;
    protocolName: string;
    data: number[];
    color: string;
    type: string;
    triggerNumber: number;
    totalTrigger: number;
    triggerState: string;
}

export interface GraphProtocolTypePercentage {
    count: number;
    percentage: number;
    type: string;
}

export interface GraphTriggerData {
    percentageChange: GraphProtocolTypePercentage[];
    protocols: GraphProtocol[];
}

export interface EvaGraphAnalyticsEvaList {
    name: string;
    id: string;
    title: string;
    isArchived: boolean;
    isPublished: boolean;
}

export interface EvaluateProtocol {
    scenarioType: string;
    conversationType: string;
    userMood1: number;
    userMood2: number;
    keywords: string[];
    protocolName: string;
    protocol: string;
    variation: boolean;
}

export enum ProtocolTypes {
    Critical = 'Critical Thinking',
    GoldStandard = 'Gold Standard',
    GoodPractice = 'Good Practice',
    Signal = 'Signal',
    Policy = 'Policy',
    Custom = 'Custom',
}
export interface ProtocolOption {
    name: ProtocolTypes;
    icon: string;
    linearBg: string;
    txtColor: string;
    description: string;
    fillIcon: string;
    lineColor: string;
    bgColor: string;
    transparentIcon: string;
}

export enum ChartType {
    LineChart = 'LineChart',
    BarChart = 'BarChart',
    GaugeChart = 'GaugeChart',
    HeatMap = 'HeatMap',
}

export enum MetricType {
    Percentage = 'Percentage',
    Ratio = 'Ratio',
    Number = 'Number',
    Score = 'Score',
    Trend = 'Trend',
    Custom = 'Custom',
    GrowthRate = 'Growth Rate',
    Velocity = 'Velocity',
    Forecast = 'Forecast',
    Rank = 'Rank',
    Index = 'Index',
    RiskLevel = 'Risk Level',
    Binary = 'Binary',
    name = 'Name',
}

export interface EvaLogItem {
    name: string;
    date: string;
    score: number;
    nuances: string;
    protocolType: string;
}

export interface Conversation {
    id: string;
    text: string;
    user: string;
    flagged: boolean;
    score: number;
}
export interface EvaluateProtocolResponse {
    evaHint: string;
    globalUser1Score: number;
    globalUser2Score: number;
    conversation: Conversation[];
    trigger?: string;
    bluePrint?: string;
}

export interface EvaAction {
    actionStr: string;
    type: string;
    parameters: {
        nuanceText: string;
        weight: number;
    };
}

export interface EvalLocalStorage {
    scenarioType: string;
    conversationType: string;
    userMood1: string;
    userMood2: string;
    keywords: string[];
    evalResult: EvaluateProtocolResponse | null;
}

export enum ActionTypes {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    FORMAT = 'FORMAT',
}

export enum EvaluateButtonTypes {
    Simulate = 'evaluateBtnTypes.simulate',
    Variation = 'evaluateBtnTypes.variation',
}

export interface SelectedGraphData {
    value: number | null; // Value can be a number or null
    protocolName: string; // Protocol name is a string
    time: string; // Time is a string (you can refine this if it's a Date object)
}
