import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, FormControl, MenuItem } from '@material-ui/core';
import Select from '@mui/material/Select';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

import { appInsights } from '../../../../../../../AppInsights';
import { ReactComponent as SearchIcon } from '../../../../../../../assets/icons/icon-search-analytics.svg';
import { ReactComponent as EmptyLog } from '../../../../../../../assets/icons/log_empty.svg';
import { ReactComponent as SortIconsSvg } from '../../../../../../../assets/icons/sort_Icon.svg';
import { ReactComponent as Upload } from '../../../../../../../assets/icons/upload.svg';
import { CreateEvaModalOpen } from '../../../../../../../contexts/CreateEvaModalContext';
import { useSubscriptionDetail } from '../../../../../../../contexts/subscription.context';
import { useOrganisationForm } from '../../../../../../../hooks/useOrganisation';
import { EvaService } from '../../../../../../../services/eva/eva.service';
import { store } from '../../../../../../../store';
import { CustomLoader } from '../../../../../../basic/CustomeLoader/CustomLoader';
import { StarIcon } from '../../../../../../basic/StarIcon.component';
import { SkeletonLoading } from '../../../../../../loading/skeletonLoading';
import Nodata from '../../../../../../Nodata';
import FormSelect from '../../../../../../profile/Form/FormSelect';
import { OptionData } from '../../../../eva/components/ProtocolTypeList';
import { EvaGraphAnalyticsEvaList, EvaLogItem, GraphPoints, SubScriptionType } from '../../../../eva/types';

interface TriggerLogProps {
    evaId: string;
}
const sortBy = ['Score', 'Date'];

type SortDirection = 'asc' | 'desc' | null;
type SortType = 'Timestamp' | 'Cat' | 'Protocols' | 'Nuances' | 'Impact';

const TriggerLog: React.FC<TriggerLogProps> = ({ evaId }) => {
    const evaService = new EvaService();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [currentLogData, setCurrentLogData] = useState<EvaLogItem[]>([]);
    const [prvLogData, setPrvLogData] = useState<EvaLogItem[]>([]);
    const [isFetchCsv, setIsFetchCsv] = useState(false);
    const [downLoadType, setDownloadType] = useState('');
    const [searchFilters, setSearchFilters] = useState('');
    const { id: organisationId } = useParams<{ id: string }>();
    const { getEvaListByOrganisationId } = useOrganisationForm();

    const downloadTime = [
        { label: t('timeFilter.All'), value: 'All' },
        { label: t('timeFilter.Day'), value: 'Day' },
        { label: t('timeFilter.Week'), value: 'Week' },
        { label: t('timeFilter.Month'), value: 'Month' },
        { label: t('timeFilter.Year'), value: 'Year' },
    ];

    const [titleList, setTitleList] = useState<EvaGraphAnalyticsEvaList[]>();
    const [evaIds, setEvaIds] = useState('');

    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const subscription = useSubscriptionDetail();
    const containerRef = useRef<HTMLUListElement>(null);

    const [sortType, setSortType] = useState<SortType | ''>('');
    const [sortDirection, setSortDirection] = useState<Record<SortType, SortDirection>>({
        Timestamp: null,
        Cat: null,
        Protocols: null,
        Nuances: null,
        Impact: null,
    });

    const [selectedShortFilters, setSelectedShortFilters] = useState({
        sortBy: '',
        order: '',
    });
    const isShieldSubscription = subscription?.productName === SubScriptionType.SHIELD;

    const organisationName = store.spaces.currentSelectedWorkSpaceInfo.workspaceName;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchFilters(value);
        setPage(1);
    };

    const {
        data: evaLogData,
        isLoading,
        isFetching,
        refetch,
    } = useQuery(
        ['LogData', evaIds, selectedShortFilters, searchFilters],
        () => {
            if (evaIds) {
                return evaService.getEvaLog(evaIds, selectedShortFilters, searchFilters, String(page));
            }
        },
        {
            enabled: Boolean(!!evaIds && containerRef),
        },
    );

    useEffect(() => {
        if (evaLogData) {
            if (page === 1) {
                setCurrentLogData(evaLogData.log.slice(0, 50));
                setPrvLogData(evaLogData.log.slice(50, 100));
            } else {
                setCurrentLogData((prevData) => [...prevData, ...prvLogData]);
                setPrvLogData(evaLogData.log);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaLogData]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        const analyticsLogList = document.querySelector('.analytics-log-list');
        // Define the scroll handler with debounce
        const handleScroll = debounce(() => {
            if (analyticsLogList) {
                const { scrollTop, scrollHeight, clientHeight } = analyticsLogList;
                // Check if the user has scrolled near the bottom of the container
                if (scrollTop + clientHeight >= scrollHeight - 100) {
                    // Load more data
                    setPage((pre: number) => {
                        if (evaLogData?.totalPage > pre) {
                            if (Math.abs(scrollTop) + clientHeight > scrollHeight - 10) {
                                return pre + 1;
                            }
                        }
                        return pre;
                    });
                }
            }
        }, 0);

        if (analyticsLogList) {
            analyticsLogList.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (analyticsLogList) {
                analyticsLogList.removeEventListener('scroll', handleScroll);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLogData, page, isFetching]);
    // Debounce utility function to limit the rate of function execution
    function debounce<T extends (...args: unknown[]) => void>(
        func: T,
        delay: number,
    ): (...args: Parameters<T>) => void {
        let timeoutId: NodeJS.Timeout | null;

        return function (...args: Parameters<T>): void {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const { isFetching: isFetchingdata, isLoading: isLoading1 } = useQuery(
        ['getEvaByOrganisationId', organisationId],
        () => getEvaListByOrganisationId(organisationId),
        {
            onSuccess: (result) => {
                if (result.id !== null) {
                    if (result) {
                        const publishedEva = result?.find((obj: EvaGraphAnalyticsEvaList) => obj.isPublished);
                        if (publishedEva) {
                            setEvaIds(publishedEva.id);
                        }
                        setTitleList(result);
                    }
                }
            },
            onError: (error: Error) => {
                appInsights.trackException({ error: error });
            },
        },
    );

    const {
        data: evaCsvLogData,
        isLoading: csvLoading,
        isFetching: csvFetching,
        refetch: csvFetch,
    } = useQuery(
        ['csv', evaIds, downLoadType],
        () => {
            if (evaIds) {
                return evaService.getEvaCsvLog(evaIds, downLoadType);
            }
        },
        {
            enabled: false, // Disable automatic fetch
        },
    );

    const handleChangeDownLoadType = (name: string) => {
        setDownloadType(name);
        setIsFetchCsv(true);
    };

    useEffect(() => {
        if (isFetchCsv) {
            csvFetch(); // Manually trigger the API call
        }
    }, [downLoadType, isFetchCsv, evaId, csvFetch]);

    const formatName = (name: string) => {
        return name?.replace(/[^a-zA-Z0-9]/g, '_').replace(/_+$/, '');
    };
    useEffect(() => {
        const formattedEvaName = evaCsvLogData?.eva ? formatName(evaCsvLogData.eva) : '';

        if (isFetchCsv && evaCsvLogData && evaCsvLogData.xlsBuffer && evaCsvLogData.xlsBuffer.data) {
            const xlsBuffer = evaCsvLogData.xlsBuffer.data;

            // Convert binary data to workbook
            const workbook = XLSX.read(new Uint8Array(xlsBuffer), { type: 'array' });

            // Write the workbook to binary .xls format
            const wbout = XLSX.write(workbook, { bookType: 'xls', type: 'array' });

            // Create a Blob with the MIME type for .xls files
            const blob = new Blob([wbout], { type: 'application/vnd.ms-excel' });

            // Use FileSaver to save the file
            saveAs(blob, `${formattedEvaName}`);

            // Reset fetch status
            setIsFetchCsv(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchCsv, evaCsvLogData]);
    const handleSortClick = (type: SortType) => {
        setPage(1);
        setSortType((prevType) => {
            const newDirection =
                sortType === type
                    ? sortDirection[type] === 'asc'
                        ? 'desc'
                        : 'asc' // Toggle the direction if the same type is clicked
                    : 'asc'; // Default to 'asc' if a new type is clicked
            setSortDirection((prevState) => ({
                ...prevState,
                [type]: newDirection,
            }));
            setSelectedShortFilters({
                sortBy: type,
                order: newDirection,
            });
            return type;
        });
    };

    useEffect(() => {
        if (titleList && !evaIds) {
            if (!evaIds) {
                setEvaIds(titleList && titleList?.length > 0 ? titleList[0]?.id : '');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaId, titleList]);

    const HandleEvaChange = (id: string) => {
        setEvaIds(id);
    };

    const handleButtonClick = async () => {
        try {
            await evaService.getCronData();
        } catch (error) {
            console.error('Error fetching new protocol type:', error);
        }
    };

    const handleEvaEventsButtonClick = async () => {
        try {
            await evaService.getCronDataEvaEvents();
        } catch (error) {
            console.error('Error fetching new protocol type:', error);
        }
    };

    const handleSnapshotsButtonClick = async () => {
        try {
            await evaService.getCronDataSnapShots();
        } catch (error) {
            console.error('Error fetching new protocol type:', error);
        }
    };

    const handleEvaVersionsButtonClick = async () => {
        try {
            await evaService.getCronDataEvaVersions();
        } catch (error) {
            console.error('Error fetching new protocol type:', error);
        }
    };

    const handleEvaEventTriggerButtonClick = async () => {
        try {
            await evaService.getCronDataEvaEventTrigger();
        } catch (error) {
            console.error('Error fetching new protocol type:', error);
        }
    };

    const handleEvaButtonClick = async () => {
        try {
            await evaService.getCronDataEva();
        } catch (error) {
            console.error('Error fetching new protocol type:', error);
        }
    };

    const handleReportsButtonClick = async () => {
        try {
            await evaService.getCronDataReports();
        } catch (error) {
            console.error('Error fetching new protocol type:', error);
        }
    };

    if (isFetchingdata) {
        return <CustomLoader />;
    }

    const { setCreateEvaModalOpen } = CreateEvaModalOpen();

    const handleOpen = () => {
        setCreateEvaModalOpen(true);
        setTimeout(() => {
            history.push(`/organisation/${organisationId}/eva`);
        }, 200);
    };

    return (
        <>
            {titleList && titleList?.length > 0 && (
                <div className='title-box'>
                    <FormControl className='select_drop_down'>
                        <Select value={evaIds} onChange={(e) => HandleEvaChange(e.target.value)}>
                            {titleList?.map((item) => {
                                return (
                                    <MenuItem
                                        className={`${
                                            item.isArchived && !item.isPublished && isShieldSubscription
                                                ? 'disable'
                                                : ''
                                        }`}
                                        value={item?.id}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('evaTrends', { name: item?.name || '' }),
                                            }}
                                        />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            )}

            {evaLogData?.log.length === 0 && evaLogData?.isLogExist === null ? (
                <Nodata
                    button={
                        <Link to={`/organisation/${id}/members`}>
                            <button className='navi_btn'>{t('reviewMembers')} </button>
                        </Link>
                    }
                    title={t(evaLogData?.msg) || t('no_logs_available')}
                    description={
                        <p>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t('enableEVA', { organisationName }),
                                }}
                            />
                        </p>
                    }
                    EmptyImage={EmptyLog}
                />
            ) : (
                <>
                    {(evaLogData?.log.length === 0 && evaLogData?.isLogExist === false) || evaIds === '' ? (
                        <>
                            <Nodata
                                button={
                                    evaIds === '' ? (
                                        <button className='blueprint_btn' onClick={handleOpen}>
                                            {t('add_protocol')}
                                        </button>
                                    ) : (
                                        <Link to={`/organisation/${id}/members`}>
                                            <button className='navi_btn'> {t('reviewMembers')} </button>
                                        </Link>
                                    )
                                }
                                title={t(evaLogData?.msg) || t('no_logs_available')}
                                description={
                                    <p>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('enableEVA', { organisationName }),
                                            }}
                                        />
                                    </p>
                                }
                                EmptyImage={EmptyLog}
                            />
                        </>
                    ) : (
                        <>
                            <Box className='card bg-primary-darker rounded-container   border logs_box  p-6 mt-6'>
                                <div className='flex justify-between relative items-center'>
                                    <p className='text-md text-white font-medium'>{t('logs')}</p>

                                    <>
                                        <div className='justify-end flex log_filter_select '>
                                            <FormSelect
                                                isUseV2={true}
                                                startIcon={<Upload />}
                                                entries={isShieldSubscription ? [] : downloadTime}
                                                value={downLoadType}
                                                onChange={(e) => handleChangeDownLoadType(e)}
                                                edit
                                                placeholder={t('allTime')}
                                                className={`${
                                                    isShieldSubscription ? 'pointer-events-none log-select_disable' : ''
                                                } log-select`}
                                                marginTop={42}
                                                disabled={isShieldSubscription}
                                                selectedShortFilters={selectedShortFilters}
                                            />

                                            {isShieldSubscription && (
                                                <div className='starIcon cursor-pointer'>
                                                    <Link to={`/organisation/${organisationId}/change-tier`}>
                                                        <StarIcon isHover={true} />
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                </div>

                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-6'>
                                    <div className='col-span-2'>
                                        <div className='relative'>
                                            <input
                                                type='text'
                                                placeholder={t('search')}
                                                value={searchFilters}
                                                onChange={handleChange}
                                                className='search-input py-2 px-4 block w-full rounded-md text-normal'
                                            />
                                            <SearchIcon className='absolute left-4 top-1/2 transform -translate-y-1/2' />
                                        </div>
                                    </div>
                                </div>
                                <div className='log_header'>
                                    <ul className='flex items-center'>
                                        {[
                                            { name: t('timestamp'), value: 'Timestamp' },
                                            { name: t('cat'), value: 'Cat' },
                                            { name: t('protocols'), value: 'Protocols' },
                                            { name: t('nuances'), value: 'Nuances' },
                                            { name: t('impact'), value: 'Impact' },
                                        ].map(({ name, value }) => (
                                            <li
                                                key={value}
                                                className={`flex items-center ${
                                                    sortType === value
                                                        ? sortDirection[value as SortType] === 'asc'
                                                            ? 'icon1'
                                                            : sortDirection[value as SortType] === 'desc'
                                                            ? 'icon2'
                                                            : ''
                                                        : ''
                                                } ${sortType === value ? 'sort' : ''}`}
                                            >
                                                <span
                                                    className='flex items-center gap-2 cursor-pointer'
                                                    onClick={() => handleSortClick(value as SortType)}
                                                >
                                                    {name}
                                                    <SortIconsSvg />
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <ul className='analytics-log-list scroll-thin ' ref={containerRef}>
                                    {isLoading || (isFetching && page == 1) ? (
                                        <SkeletonLoading background='white' height='50px' />
                                    ) : (
                                        <>
                                            {evaLogData?.log.length === 0 && evaLogData?.isLogExist === true ? (
                                                <>
                                                    <p className='no_data mt-4'> {evaLogData?.msg}</p>{' '}
                                                </>
                                            ) : (
                                                <>
                                                    {currentLogData?.map((item: EvaLogItem, index: number) => (
                                                        <li key={index} className='flex items-center record'>
                                                            <p className='text-normal date_time'>
                                                                <span className='date_time'>
                                                                    {moment(item.date).format(
                                                                        'DD/MM/YY [ : ] HH:mm:ss',
                                                                    )}
                                                                </span>{' '}
                                                            </p>
                                                            <p className='text-normal text-white  relative pl-2.5 flex gap-1 items-center'>
                                                                <span className='protocolType'>
                                                                    <img
                                                                        src={`${
                                                                            OptionData.find(
                                                                                (obj) => obj.name === item.protocolType,
                                                                            )?.icon
                                                                        }`}
                                                                        alt=''
                                                                    />{' '}
                                                                    {/* {item.protocolType} */}
                                                                </span>{' '}
                                                            </p>
                                                            <p className='text-normal text-white relative protocol_name  log_dots  '>
                                                                <label className='overflow-hidden'>
                                                                    {' '}
                                                                    <span
                                                                        className={`${
                                                                            item?.name?.length > 32 ? 'scroll_span' : ''
                                                                        } name   `}
                                                                    >
                                                                        {item.name}
                                                                    </span>{' '}
                                                                </label>
                                                            </p>
                                                            <p className='text-normal text-white relative protocol_nuances   log_dots '>
                                                                <label className='overflow-hidden'>
                                                                    <span
                                                                        className={`${
                                                                            item?.nuances?.length > 32
                                                                                ? 'scroll_span'
                                                                                : ''
                                                                        }  nuances`}
                                                                    >
                                                                        {' '}
                                                                        {item.nuances}
                                                                    </span>{' '}
                                                                </label>
                                                            </p>
                                                            <p className='text-normal text-white relative log_dots '>
                                                                {' '}
                                                                <span className='score'>{item.score}</span>{' '}
                                                            </p>
                                                        </li>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {isLoading ||
                                        (isFetching && page !== 1 && (
                                            <SkeletonLoading background='white' height='30px' />
                                        ))}
                                </ul>
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default observer(TriggerLog);
